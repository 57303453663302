import { useEffect, useState } from "react";

import { FormProvider, useFormContext } from "react-hook-form";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import Divider from "components/Divider";
import { InternalTextLink } from "components/Links";
import { Spinner } from "components/Spinner";
import { FadeTransition } from "components/animations/FadeTransition";
import CreatePatientForm from "components/organisations/checkout/forms/CreatePatientForm";
import PatientDetails from "components/organisations/checkout/ui/PatientDetails";
import {
  CMP_PERMISSION_ADD_PATIENTS,
  CMP_PERMISSION_ADMIN
} from "components/organisations/constants";
import AddOnDetail from "components/organisations/shared/ui/AddOnDetail";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PARTNERS_ORGANISATIONS_URL } from "core/urls";
import RefetchEvent from "events/refetch";
import {
  ADD_ON_TO_STOCK_MUTATION,
  CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION,
  CREATE_STOCK_PAYMENT_INVOICE_MUTATION,
  REMOVE_ADD_ON_FROM_STOCK_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import { ORGANISATION_ORDERS_LIST_QUERY } from "graphql/organisations/queries/orders";
import {
  ORGANISATION_STOCK_DETAILS_QUERY,
  ORGANISATION_STOCK_LIST_QUERY
} from "graphql/organisations/queries/stock";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import { useDidUpdate } from "hooks/useDidUpdate";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import Currency from "tpo/Currency";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import { Error } from "tpo/InputWrapper";
import Modal from "tpo/Modal";
import { SelectPatientField } from "tpo/SelectPatient";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const CREATE_STOCK_PAYMENT_INVOICE_MUTATION_AND_NAME = {
  mutation: CREATE_STOCK_PAYMENT_INVOICE_MUTATION,
  mutationName: "createStockPaymentInvoiceMutation"
};

const CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION_AND_NAME = {
  mutation: CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION,
  mutationName: "createStockPaymentCheckoutSessionMutation"
};

function FormSubmitButton({ loading, requiresCheckout, requiresBarcode }) {
  const { watch } = useFormContext();

  const [patient, barcode] = watch(["patient", "barcode"]);

  return (
    <ButtonV2
      color="green"
      rightIcon={loading ? null : <ChevronComponent />}
      disabled={!patient || (requiresBarcode && !barcode) || loading}
      size={["sm", "sm", "md"]}
    >
      {requiresCheckout ? "pay & register" : "register & pay"}
    </ButtonV2>
  );
}

export default function RegisterStockPanel({ stock, onComplete }) {
  const hasBarcode = stock.omnosBarcodeId;

  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);
  const { organisation, permissions } = useOrganisationContext();

  const [registerMutationQuery, setRegisterMutationQuery] = useState(
    CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION_AND_NAME
  );

  const requiresCheckout = !organisation?.automaticInvoicingEnabled;

  const formApi = useDjangoGraphqlForm({
    ...registerMutationQuery,
    mutationOptions: {
      refetchQueries: [
        {
          query: ORGANISATION_STOCK_DETAILS_QUERY,
          variables: {
            id: stock?.id,
            organisation: parseInt(organisation?.id)
          }
        },
        {
          query: ORGANISATION_STOCK_LIST_QUERY,
          variables: {
            organisation: parseInt(organisation?.id),
            page: 1,
            orderBy: "-created_from_order__checkout_date"
          }
        },
        {
          query: ORGANISATION_ORDERS_LIST_QUERY,
          variables: {
            organisation: parseInt(organisation?.id),
            page: 1,
            orderBy: "-checkout_date"
          }
        }
      ]
    },
    handleSuccess: ({ data }) => {
      if (data?.createStockPaymentCheckoutSessionMutation?.paymentCheckoutSession?.url) {
        window.location.href =
          data?.createStockPaymentCheckoutSessionMutation?.paymentCheckoutSession?.url;
      } else {
        RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
      }
    },
    defaultValues: {
      stock: stock.id,
      patient: "",
      organisation: organisation?.id,
      barcode: ""
    },
    transformer: data => ({
      ...data,
      patient: organisation.patients.find(patient => patient.id === patientId)?.platformUserProfile
        ?.pk
    })
  });

  useDidUpdate(() => {
    if (organisation) {
      formApi.methods.setValue("organisation", organisation.id);
    }
  }, [organisation]);

  const patientId = formApi.methods.watch("patient");
  const patient = organisation.patients.find(patient => patient.id === patientId);

  const [addOnToStockMutation] = useMutation(ADD_ON_TO_STOCK_MUTATION, {
    refetchQueries: [
      {
        query: ORGANISATION_STOCK_DETAILS_QUERY,
        variables: {
          id: stock?.id,
          organisation: parseInt(organisation?.id)
        }
      }
    ]
  });

  const [removeAddOnFromStockMutation] = useMutation(REMOVE_ADD_ON_FROM_STOCK_MUTATION, {
    refetchQueries: [
      {
        query: ORGANISATION_STOCK_DETAILS_QUERY,
        variables: {
          id: stock?.id,
          organisation: parseInt(organisation?.id)
        }
      }
    ]
  });

  useEffect(() => {
    if (organisation?.automaticInvoicingEnabled) {
      setRegisterMutationQuery(CREATE_STOCK_PAYMENT_INVOICE_MUTATION_AND_NAME);
    } else {
      setRegisterMutationQuery(CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION_AND_NAME);
    }
  }, [organisation]);

  return (
    <>
      <FormProvider {...formApi.methods}>
        <Stack
          gap={[20, 20, 40]}
          as="form"
          onSubmit={formApi.methods.handleSubmit(formApi.onSubmit)}
        >
          <Box>
            <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]} lineHeight="130%">
              Register this kit to a patient
            </Box>
            <Spacer py={2} />
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              Once you register this kit to a patient your business will be invoiced for the
              remaining balance of the test.
            </Box>
          </Box>
          {hasBarcode && (
            <FormControl
              label={
                <Box fontFamily="gilroyBold" fontSize={18} lineHeight="36px">
                  Confirm kit registration number
                </Box>
              }
            >
              <ControlledFormField
                Component={FloatingLabelInput}
                name="barcode"
                label="Enter barcode"
              />
            </FormControl>
          )}
          <ControlledFormField
            Component={SelectPatientField}
            label="Select patient"
            patients={organisation.patients}
            name="patient"
            labelProps={{
              fontFamily: "gilroyBold",
              fontSize: 18,
              lineHeight: "36px",
              mb: 20
            }}
          />
          {!!(permissions && permissions.includes(CMP_PERMISSION_ADMIN)) ? (
            <ButtonV2
              color="green"
              onClick={() => setShowCreatePatientModal(true)}
              rightIcon={<ChevronComponent />}
              maxWidth={310}
              type="button"
              size={["sm", "sm", "md"]}
            >
              create a new patient
            </ButtonV2>
          ) : (
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} color="midGrey">
              Please contact your team manager if you need to add additional patients
            </Box>
          )}
          <Divider color="#1A1A1A" />
          <FadeTransition in={!!patient}>
            {patient && <PatientDetails patient={patient} />}
          </FadeTransition>
          {!!stock.testProduct?.addons?.length && (
            <>
              <Stack gap={[40]}>
                <Box>
                  <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="130%">
                    Additional marker add ons
                  </Box>
                  <Spacer py={2} />
                  <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                    This test has additional markers that can be included in your purchase. If you
                    wish to include these markers in the stock registration please select from
                    below.
                  </Box>
                </Box>
                <Stack
                  gap={1}
                  maxHeight="calc(122.4px * 5 + 5 * 1px)"
                  style={{
                    overflow: "auto"
                  }}
                  bg="haze"
                >
                  {stock.testProduct?.addons.map(addon => (
                    <AddOnDetail
                      key={addon.id}
                      name={addon.name}
                      description={addon.content.description}
                      price={addon.tradeCurrentPrice}
                      state={
                        stock.addons.map(addon => addon.id).includes(addon.id)
                          ? "selected"
                          : "unselected"
                      }
                      onRemove={() =>
                        removeAddOnFromStockMutation({
                          variables: {
                            input: {
                              addon: addon.id,
                              stock: stock?.id,
                              organisation: organisation?.id
                            }
                          }
                        })
                      }
                      onAdd={() =>
                        addOnToStockMutation({
                          variables: {
                            input: {
                              addon: addon.id,
                              stock: stock?.id,
                              organisation: organisation?.id
                            }
                          }
                        })
                      }
                    />
                  ))}
                </Stack>
              </Stack>
              <Divider color="#1A1A1A" />
            </>
          )}
          <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="130%">
            Remaining Balance
          </Box>
          {requiresCheckout && (
            <Box>
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                You don’t currently have{" "}
                <InternalTextLink href={PARTNERS_ORGANISATIONS_URL}>
                  automatic invoicing enabled
                </InternalTextLink>
                . Activate in your organisation settings to defer payment via invoice for up to 30
                days. Alternatively you can pay with a card to complete this order now.
              </Box>
            </Box>
          )}
          <Stack width="100%" alignItems="flex-end" gap={0}>
            <Box>
              <Currency
                data-testid="TestTotal"
                prefix={"TEST TOTAL"}
                value={stock.remainingCost}
                fontSize={28}
              />
              <Spacer py={1} />
            </Box>
            <Box>
              <Currency
                data-testid="ReturnShipping"
                prefix={"RETURN SHIPPING"}
                value={stock.returnShippingCost}
                fontSize={28}
              />
              <Spacer py={1} />
            </Box>
            <Box>
              <Currency
                data-testid="GrandTotal"
                prefix={"TOTAL"}
                value={stock.remainingCost + stock.returnShippingCost}
                fontSize={44}
              />
              <Spacer py={1} />
            </Box>
          </Stack>
          {formApi.loading ? (
            <Center width="150px" height="57px">
              <Spinner margin="auto" />
            </Center>
          ) : (
            <Box width="100%" flexDirection="row" display="flex" justifyContent="flex-end">
              <FormSubmitButton
                loading={formApi.loading}
                requiresCheckout={requiresCheckout}
                requiresBarcode={hasBarcode}
              />
            </Box>
          )}
          <FadeTransition in={!!formApi.error}>
            <Box color="red" fontSize={16}>
              {formApi.error?.message}
            </Box>
          </FadeTransition>
          <FadeTransition in={!!formApi.nonFieldError}>
            <Center>
              <Error error={formApi.nonFieldError} />
            </Center>
          </FadeTransition>
        </Stack>
      </FormProvider>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [20, 20, 40]
        }}
        bg="white"
        show={showCreatePatientModal}
        close={() => setShowCreatePatientModal(false)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <Box px={[20]} maxWidth={760} mx="auto">
          <CreatePatientForm
            handleSuccess={({ data }) => {
              const id = data?.createPatientMutation?.patient?.id;
              formApi.methods.setValue("patient", id);
              setShowCreatePatientModal(false);
            }}
          />
        </Box>
      </Modal>
    </>
  );
}
