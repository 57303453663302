import gql from "graphql-tag";

import {
  CORE_USER_THEME_WITH_SECTORS_FIELDS,
  THEME_FIELDS,
  THEME_FIELDS_TRUNCATED,
  USER_SUBSECTOR_FIELDS
} from "./types";

export const THEMES_QUERY = gql`
  query ThemesQuery {
    themes {
      ...ThemeFieldsTruncated
    }
  }
  ${THEME_FIELDS_TRUNCATED}
`;

export const THEME_QUERY = gql`
  query ThemeQuery($themeId: ID!) {
    theme(themeId: $themeId) {
      ...ThemeFields
    }
  }
  ${THEME_FIELDS}
`;

export const USER_THEME_QUERY = gql`
  query UserThemeQuery($themeSlug: String!) {
    userTheme(themeSlug: $themeSlug) {
      ...CoreUserThemeWithSectorsFields
    }
    userSubsectors(themeSlug: $themeSlug) {
      ...UserSubsectorFields
      userResultCount
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
    }
  }
  ${CORE_USER_THEME_WITH_SECTORS_FIELDS}
  ${USER_SUBSECTOR_FIELDS}
`;
