import Box from "components/Box";
import { MODAL_Z_INDEX } from "core/constants";
import Group from "tpo/Group";
import ToggleSwitch from "tpo/ToggleSwitch";
import Tooltip from "tpo/Tooltip";
import { QuestionButton } from "v2/Buttons";

import AddOnErrorModal from "./AddOnErrorModal";
import MultipleProductsErrorModal from "./MultipleProductsErrorModal";
import StockPatientErrorModal from "./StockPatientErrorModal";

export function OrganisationPayeeOptions({ basket, onboardingComplete, onBasketUpdate }) {
  // if any of basket.productLineItems have a valid .clinicLocation, then disable the toggle

  return onboardingComplete ? (
    <>
      <Group gap={20} position="relative">
        <Group alignItems="center" gap={10}>
          <Tooltip
            content={
              <Box
                bg="black"
                color="white"
                maxWidth={200}
                p={2}
                pb={20}
                borderRadius={5}
                zIndex={MODAL_Z_INDEX + 1}
                position="relative"
              >
                When you order as stock you will only be charged for the kit price + postage. Once
                the kit is registered we will charge the remaining balance + a postage fee to return
                to the lab.
              </Box>
            }
            placement="bottom-end"
          >
            <QuestionButton bg="dark" color="white" />
          </Tooltip>
          <Box color="dark" fontFamily="gilroyBold" fontSize={15} lineHeight="24px">
            Order as stock?
          </Box>
        </Group>
        <ToggleSwitch
          onChange={purchaseAsStock => onBasketUpdate({ purchaseAsStock })}
          value={basket?.purchaseAsStock}
          data-testid="order-as-stock-toggle"
          id="order-as-stock-toggle"
          offBg="haze"
        />
      </Group>
      <AddOnErrorModal />
      <MultipleProductsErrorModal />
      <StockPatientErrorModal />
    </>
  ) : null;
}
