import { useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import { Spinner } from "components/Spinner";
import Text from "components/Text";
import { FadeTransition } from "components/animations/FadeTransition";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { theme } from "core/theme";
import { getPartnerDashboardOrderUrl } from "core/urls";
import RefetchEvent from "events/refetch";
import {
  CREATE_PAYMENT_INVOICE_MUTATION,
  UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import { ORGANISATION_ORDERS_LIST_QUERY } from "graphql/organisations/queries/orders";
import { ORGANISATION_BASKET_DETAIL_QUERY } from "graphql/organisations/queries/organisation";
import { ORGANISATION_STOCK_LIST_QUERY } from "graphql/organisations/queries/stock";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

export default function CreateOrderInvoiceForm({ canSubmit }) {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();
  const history = useHistory();

  const [createPaymentInvoiceMutation, { data, loading, error }] = useMutation(
    CREATE_PAYMENT_INVOICE_MUTATION,
    {
      variables: {
        input: {
          basket: basket.id,
          organisation: organisation.id
        }
      },
      refetchQueries: [
        {
          query: ORGANISATION_BASKET_DETAIL_QUERY,
          variables: {
            organisation: parseInt(organisation?.id)
          }
        }
      ]
    }
  );
  useEffect(() => {
    if (data?.createPaymentInvoiceMutation?.order?.id) {
      RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
      RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);

      history.push(getPartnerDashboardOrderUrl(data.createPaymentInvoiceMutation.order.id));
    }
  }, [data, history]);

  const [updateAutomaticInvoicingSettingMutation] = useMutation(
    UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION
  );

  const updateAutomaticInvoicingSetting = useCallback(
    async variables => {
      if (!organisation?.id) {
        return;
      }
      try {
        await updateAutomaticInvoicingSettingMutation({
          variables: {
            input: {
              id: parseInt(organisation.id),
              ...variables
            }
          }
        });
      } catch (e) {}
    },
    [updateAutomaticInvoicingSettingMutation, organisation]
  );

  const handleToggleAutomaticInvoicing = useCallback(
    checked => {
      updateAutomaticInvoicingSetting({
        automaticInvoicingEnabled: checked
      });
    },
    [updateAutomaticInvoicingSetting]
  );

  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        bg: "white",
        px: [20, 20, 40],
        pt: theme.spacing.section.pt,
        pb: theme.spacing.section.pb,
        borderRadius: 5
      }}
    >
      <Box fontFamily="gilroyBold" fontSize={28}>
        Payment Details
      </Box>
      <Spacer py={2} />
      <Box fontFamily="gilroyMedium" fontSize={16}>
        <FadeTransition in={!organisation?.automaticInvoicingEnabled}>
          {basket?.purchaseAsStock ? (
            <Text>
              By ordering these kits as stock you will need to have automatic invoice payment
              enabled before you can register a kit to a patient. Automatic invoicing means that
              payment will be withdrawn from your account at the invoice due date.
            </Text>
          ) : (
            <>
              <Text mb={4}>
                Since you've connected your business bank account, we'll create an invoice that you
                need to pay within 30 days of placing this order.
              </Text>
              <Text>
                For a more seamless payment integration you can opt in to have invoices
                automatically taken from your account when they are due. If you opt in we will give
                you an extra 5% discount on your first order.
              </Text>
            </>
          )}
          <Spacer py={4} />
          <Checkbox
            label="Allow payment to be automatically taken after 45 days"
            checked={organisation?.automaticInvoicingEnabled}
            onChange={({ target }) => handleToggleAutomaticInvoicing(target.checked)}
          />
        </FadeTransition>
        <FadeTransition in={organisation?.automaticInvoicingEnabled}>
          <p>
            Because you linked your business bank account and set up automatic payments, we'll make
            an invoice that gets automatically withdrawn 45 days after you place the order.
          </p>
        </FadeTransition>
      </Box>
      <Spacer py={15} />
      <Center>
        <FadeTransition in={loading}>
          <Spinner />
        </FadeTransition>
        <FadeTransition in={!loading}>
          <ButtonV2
            color={canSubmit ? "green" : "grey"}
            disabled={!canSubmit}
            rightIcon={<ChevronComponent />}
            onClick={() => createPaymentInvoiceMutation()}
            size={["sm", "sm", "md"]}
          >
            place order
          </ButtonV2>
        </FadeTransition>
      </Center>
    </PanelBoxV2>
  );
}
