export default function RegenerusLogoMobile({ fill, style }) {
  return (
    <svg
      width="82"
      height="29"
      viewBox="0 0 82 29"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_8361_25311)">
        <path
          d="M53.4341 16.8278C54.5717 16.8278 55.4938 15.8976 55.4938 14.7502C55.4938 13.6029 54.5717 12.6727 53.4341 12.6727C52.2966 12.6727 51.3745 13.6029 51.3745 14.7502C51.3745 15.8976 52.2966 16.8278 53.4341 16.8278Z"
          fill="inherit"
        />
        <g opacity="0.7">
          <path
            d="M40.676 10.8838C41.8135 10.8838 42.7357 9.95365 42.7357 8.80627C42.7357 7.65889 41.8135 6.72876 40.676 6.72876C39.5384 6.72876 38.6163 7.65889 38.6163 8.80627C38.6163 9.95365 39.5384 10.8838 40.676 10.8838Z"
            fill="inherit"
          />
          <path
            d="M40.6774 5.00903C41.815 5.00903 42.7371 4.0789 42.7371 2.93152C42.7371 1.78414 41.815 0.854004 40.6774 0.854004C39.5399 0.854004 38.6177 1.78414 38.6177 2.93152C38.6177 4.0789 39.5399 5.00903 40.6774 5.00903Z"
            fill="inherit"
          />
          <path
            d="M42.0856 4.44475V4.4479L41.7214 4.92394C41.5574 5.13831 41.4589 5.37632 41.437 5.62064C41.423 5.783 41.4214 5.94693 41.4355 6.10928C41.4558 6.35833 41.5558 6.5995 41.723 6.81545L42.0856 7.28517V7.28833L40.6807 8.81415L39.2695 7.28833V7.28517L39.6227 6.83279C39.793 6.61526 39.8946 6.37094 39.9149 6.12032C39.9305 5.9485 39.929 5.77512 39.9118 5.6033C39.8884 5.35898 39.7899 5.12097 39.6242 4.9066L39.2679 4.44633V4.44318L40.6791 2.93469L42.084 4.44318L42.0856 4.44475Z"
            fill="inherit"
          />
          <path
            d="M40.676 16.7522C41.8135 16.7522 42.7357 15.8221 42.7357 14.6747C42.7357 13.5273 41.8135 12.5972 40.676 12.5972C39.5384 12.5972 38.6163 13.5273 38.6163 14.6747C38.6163 15.8221 39.5384 16.7522 40.676 16.7522Z"
            fill="inherit"
          />
          <path
            d="M42.0856 10.3132V10.3163L41.7214 10.7923C41.5574 11.0067 41.4589 11.2447 41.437 11.489C41.423 11.6514 41.4214 11.8153 41.4355 11.9777C41.4558 12.2267 41.5558 12.4679 41.723 12.6839L42.0856 13.1536V13.1567L40.6807 14.6826L39.2695 13.1567V13.1536L39.6227 12.7012C39.793 12.4837 39.8946 12.2394 39.9149 11.9887C39.9305 11.8169 39.929 11.6435 39.9118 11.4717C39.8884 11.2274 39.7899 10.9894 39.6242 10.775L39.2679 10.3147V10.3116L40.6791 8.8031L42.084 10.3116L42.0856 10.3132Z"
            fill="inherit"
          />
          <path
            d="M40.676 22.6284C41.8135 22.6284 42.7357 21.6983 42.7357 20.5509C42.7357 19.4035 41.8135 18.4734 40.676 18.4734C39.5384 18.4734 38.6163 19.4035 38.6163 20.5509C38.6163 21.6983 39.5384 22.6284 40.676 22.6284Z"
            fill="inherit"
          />
          <path
            d="M42.0856 16.1895V16.1927L41.7214 16.6687C41.5574 16.8831 41.4589 17.1211 41.437 17.3654C41.423 17.5277 41.4214 17.6917 41.4355 17.854C41.4558 18.1031 41.5558 18.3443 41.723 18.5602L42.0856 19.0299V19.0331L40.6807 20.5589L39.2695 19.0331V19.0299L39.6227 18.5775C39.793 18.36 39.8946 18.1157 39.9149 17.8651C39.9305 17.6933 39.929 17.5199 39.9118 17.3481C39.8884 17.1037 39.7899 16.8657 39.6242 16.6513L39.2679 16.1911V16.1879L40.6791 14.6794L42.084 16.1879L42.0856 16.1895Z"
            fill="inherit"
          />
          <path
            d="M40.676 28.5C41.8135 28.5 42.7357 27.5699 42.7357 26.4225C42.7357 25.2751 41.8135 24.345 40.676 24.345C39.5384 24.345 38.6163 25.2751 38.6163 26.4225C38.6163 27.5699 39.5384 28.5 40.676 28.5Z"
            fill="inherit"
          />
          <path
            d="M42.0856 22.061V22.0641L41.7214 22.5401C41.5574 22.7545 41.4589 22.9925 41.437 23.2369C41.423 23.3992 41.4214 23.5631 41.4355 23.7255C41.4558 23.9745 41.5558 24.2157 41.723 24.4317L42.0856 24.9014V24.9045L40.6807 26.4304L39.2695 24.9045V24.9014L39.6227 24.449C39.793 24.2315 39.8946 23.9872 39.9149 23.7365C39.9305 23.5647 39.929 23.3913 39.9118 23.2195C39.8884 22.9752 39.7899 22.7372 39.6242 22.5228L39.2679 22.0625V22.0594L40.6791 20.5509L42.084 22.0594L42.0856 22.061Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M47.0551 10.9073C48.1926 10.9073 49.1148 9.97721 49.1148 8.82983C49.1148 7.68245 48.1926 6.75232 47.0551 6.75232C45.9175 6.75232 44.9954 7.68245 44.9954 8.82983C44.9954 9.97721 45.9175 10.9073 47.0551 10.9073Z"
            fill="inherit"
          />
          <path
            d="M47.0551 16.7758C48.1926 16.7758 49.1148 15.8456 49.1148 14.6982C49.1148 13.5509 48.1926 12.6207 47.0551 12.6207C45.9175 12.6207 44.9954 13.5509 44.9954 14.6982C44.9954 15.8456 45.9175 16.7758 47.0551 16.7758Z"
            fill="inherit"
          />
          <path
            d="M48.4646 10.3367V10.3399L48.1005 10.8159C47.9365 11.0303 47.838 11.2683 47.8161 11.5126C47.8021 11.675 47.8005 11.8389 47.8146 12.0012C47.8349 12.2503 47.9349 12.4915 48.1021 12.7074L48.4646 13.1771V13.1803L47.0597 14.7061L45.6486 13.1803V13.1771L46.0018 12.7248C46.1721 12.5072 46.2737 12.2629 46.294 12.0123C46.3096 11.8405 46.3081 11.6671 46.2909 11.4953C46.2674 11.251 46.169 11.0129 46.0033 10.7986L45.647 10.3383V10.3351L47.0582 8.82666L48.4631 10.3351L48.4646 10.3367Z"
            fill="inherit"
          />
          <path
            d="M47.0551 22.6521C48.1926 22.6521 49.1148 21.722 49.1148 20.5746C49.1148 19.4272 48.1926 18.4971 47.0551 18.4971C45.9175 18.4971 44.9954 19.4272 44.9954 20.5746C44.9954 21.722 45.9175 22.6521 47.0551 22.6521Z"
            fill="inherit"
          />
          <path
            d="M48.4646 16.2115V16.2146L48.1005 16.6907C47.9365 16.905 47.838 17.143 47.8161 17.3874C47.8021 17.5497 47.8005 17.7136 47.8146 17.876C47.8349 18.1251 47.9349 18.3662 48.1021 18.5822L48.4646 19.0519V19.055L47.0597 20.5809L45.6486 19.055V19.0519L46.0018 18.5995C46.1721 18.382 46.2737 18.1377 46.294 17.887C46.3096 17.7152 46.3081 17.5418 46.2909 17.37C46.2674 17.1257 46.169 16.8877 46.0033 16.6733L45.647 16.213V16.2099L47.0582 14.7014L48.4631 16.2099L48.4646 16.2115Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M34.3125 10.9073C35.45 10.9073 36.3722 9.97721 36.3722 8.82983C36.3722 7.68245 35.45 6.75232 34.3125 6.75232C33.175 6.75232 32.2528 7.68245 32.2528 8.82983C32.2528 9.97721 33.175 10.9073 34.3125 10.9073Z"
            fill="inherit"
          />
          <path
            d="M34.3125 16.7758C35.45 16.7758 36.3722 15.8456 36.3722 14.6982C36.3722 13.5509 35.45 12.6207 34.3125 12.6207C33.175 12.6207 32.2528 13.5509 32.2528 14.6982C32.2528 15.8456 33.175 16.7758 34.3125 16.7758Z"
            fill="inherit"
          />
          <path
            d="M35.7205 10.3367V10.3399L35.3564 10.8159C35.1923 11.0303 35.0938 11.2683 35.072 11.5126C35.0579 11.675 35.0563 11.8389 35.0704 12.0012C35.0907 12.2503 35.1907 12.4915 35.358 12.7074L35.7205 13.1771V13.1803L34.3156 14.7061L32.9044 13.1803V13.1771L33.2576 12.7248C33.428 12.5072 33.5295 12.2629 33.5499 12.0123C33.5655 11.8405 33.5639 11.6671 33.5467 11.4953C33.5233 11.251 33.4248 11.0129 33.2592 10.7986L32.9029 10.3383V10.3351L34.314 8.82666L35.7189 10.3351L35.7205 10.3367Z"
            fill="inherit"
          />
          <path
            d="M34.3125 22.6521C35.45 22.6521 36.3722 21.722 36.3722 20.5746C36.3722 19.4272 35.45 18.4971 34.3125 18.4971C33.175 18.4971 32.2528 19.4272 32.2528 20.5746C32.2528 21.722 33.175 22.6521 34.3125 22.6521Z"
            fill="inherit"
          />
          <path
            d="M35.7205 16.2115V16.2146L35.3564 16.6907C35.1923 16.905 35.0938 17.143 35.072 17.3874C35.0579 17.5497 35.0563 17.7136 35.0704 17.876C35.0907 18.1251 35.1907 18.3662 35.358 18.5822L35.7205 19.0519V19.055L34.3156 20.5809L32.9044 19.055V19.0519L33.2576 18.5995C33.428 18.382 33.5295 18.1377 33.5499 17.887C33.5655 17.7152 33.5639 17.5418 33.5467 17.37C33.5233 17.1257 33.4248 16.8877 33.2592 16.6733L32.9029 16.213V16.2099L34.314 14.7014L35.7189 16.2099L35.7205 16.2115Z"
            fill="inherit"
          />
        </g>
        <path
          d="M27.9474 16.8278C29.085 16.8278 30.0071 15.8976 30.0071 14.7502C30.0071 13.6029 29.085 12.6727 27.9474 12.6727C26.8099 12.6727 25.8878 13.6029 25.8878 14.7502C25.8878 15.8976 26.8099 16.8278 27.9474 16.8278Z"
          fill="inherit"
        />
        <g opacity="0.7">
          <path
            d="M15.1845 10.8759C16.322 10.8759 17.2442 9.94572 17.2442 8.79834C17.2442 7.65096 16.322 6.72083 15.1845 6.72083C14.047 6.72083 13.1248 7.65096 13.1248 8.79834C13.1248 9.94572 14.047 10.8759 15.1845 10.8759Z"
            fill="inherit"
          />
          <path
            d="M15.186 5.00109C16.3236 5.00109 17.2457 4.07096 17.2457 2.92358C17.2457 1.7762 16.3236 0.846069 15.186 0.846069C14.0485 0.846069 13.1263 1.7762 13.1263 2.92358C13.1263 4.07096 14.0485 5.00109 15.186 5.00109Z"
            fill="inherit"
          />
          <path
            d="M16.5925 4.43681V4.43997L16.2284 4.916C16.0643 5.13037 15.9659 5.36839 15.944 5.61271C15.9299 5.77506 15.9284 5.93899 15.9424 6.10135C15.9627 6.3504 16.0628 6.59156 16.23 6.80751L16.5925 7.27724V7.28039L15.1876 8.80621L13.7765 7.28039V7.27724L14.1296 6.82485C14.3 6.60733 14.4016 6.36301 14.4219 6.11238C14.4375 5.94057 14.4359 5.76718 14.4188 5.59537C14.3953 5.35105 14.2969 5.11303 14.1312 4.89866L13.7749 4.43839V4.43524L15.1861 2.92676L16.591 4.43524L16.5925 4.43681Z"
            fill="inherit"
          />
          <path
            d="M15.1845 16.7443C16.322 16.7443 17.2442 15.8141 17.2442 14.6667C17.2442 13.5194 16.322 12.5892 15.1845 12.5892C14.047 12.5892 13.1248 13.5194 13.1248 14.6667C13.1248 15.8141 14.047 16.7443 15.1845 16.7443Z"
            fill="inherit"
          />
          <path
            d="M16.5925 10.3052V10.3084L16.2284 10.7844C16.0643 10.9988 15.9659 11.2368 15.944 11.4811C15.9299 11.6435 15.9284 11.8074 15.9424 11.9698C15.9627 12.2188 16.0628 12.46 16.23 12.6759L16.5925 13.1456V13.1488L15.1876 14.6746L13.7765 13.1488V13.1456L14.1296 12.6933C14.3 12.4757 14.4016 12.2314 14.4219 11.9808C14.4375 11.809 14.4359 11.6356 14.4188 11.4638C14.3953 11.2195 14.2969 10.9814 14.1312 10.7671L13.7749 10.3068V10.3036L15.1861 8.79517L16.591 10.3036L16.5925 10.3052Z"
            fill="inherit"
          />
          <path
            d="M15.1845 22.6206C16.322 22.6206 17.2442 21.6905 17.2442 20.5431C17.2442 19.3957 16.322 18.4656 15.1845 18.4656C14.047 18.4656 13.1248 19.3957 13.1248 20.5431C13.1248 21.6905 14.047 22.6206 15.1845 22.6206Z"
            fill="inherit"
          />
          <path
            d="M16.5925 16.18V16.1831L16.2284 16.6592C16.0643 16.8735 15.9659 17.1116 15.944 17.3559C15.9299 17.5182 15.9284 17.6822 15.9424 17.8445C15.9627 18.0936 16.0628 18.3347 16.23 18.5507L16.5925 19.0204V19.0236L15.1876 20.5494L13.7765 19.0236V19.0204L14.1296 18.568C14.3 18.3505 14.4016 18.1062 14.4219 17.8555C14.4375 17.6837 14.4359 17.5103 14.4188 17.3385C14.3953 17.0942 14.2969 16.8562 14.1312 16.6418L13.7749 16.1816V16.1784L15.1861 14.6699L16.591 16.1784L16.5925 16.18Z"
            fill="inherit"
          />
          <path
            d="M15.1845 28.4921C16.322 28.4921 17.2442 27.5619 17.2442 26.4145C17.2442 25.2672 16.322 24.337 15.1845 24.337C14.047 24.337 13.1248 25.2672 13.1248 26.4145C13.1248 27.5619 14.047 28.4921 15.1845 28.4921Z"
            fill="inherit"
          />
          <path
            d="M16.5925 22.053V22.0562L16.2284 22.5322C16.0643 22.7466 15.9659 22.9846 15.944 23.2289C15.9299 23.3913 15.9284 23.5552 15.9424 23.7176C15.9627 23.9666 16.0628 24.2078 16.23 24.4237L16.5925 24.8935V24.8966L15.1876 26.4224L13.7765 24.8966V24.8935L14.1296 24.4411C14.3 24.2235 14.4016 23.9792 14.4219 23.7286C14.4375 23.5568 14.4359 23.3834 14.4188 23.2116C14.3953 22.9673 14.2969 22.7292 14.1312 22.5149L13.7749 22.0546V22.0515L15.1861 20.543L16.591 22.0515L16.5925 22.053Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M21.5636 10.8994C22.7011 10.8994 23.6233 9.96928 23.6233 8.8219C23.6233 7.67452 22.7011 6.74438 21.5636 6.74438C20.4261 6.74438 19.5039 7.67452 19.5039 8.8219C19.5039 9.96928 20.4261 10.8994 21.5636 10.8994Z"
            fill="inherit"
          />
          <path
            d="M21.5636 16.7678C22.7011 16.7678 23.6233 15.8377 23.6233 14.6903C23.6233 13.5429 22.7011 12.6128 21.5636 12.6128C20.4261 12.6128 19.5039 13.5429 19.5039 14.6903C19.5039 15.8377 20.4261 16.7678 21.5636 16.7678Z"
            fill="inherit"
          />
          <path
            d="M22.9716 10.3272V10.3303L22.6075 10.8064C22.4434 11.0208 22.345 11.2588 22.3231 11.5031C22.309 11.6654 22.3074 11.8294 22.3215 11.9917C22.3418 12.2408 22.4418 12.4819 22.6091 12.6979L22.9716 13.1676V13.1708L21.5667 14.6966L20.1556 13.1708V13.1676L20.5087 12.7152C20.6791 12.4977 20.7807 12.2534 20.801 12.0028C20.8166 11.831 20.815 11.6576 20.7978 11.4857C20.7744 11.2414 20.6759 11.0034 20.5103 10.789L20.154 10.3288V10.3256L21.5651 8.81714L22.9701 10.3256L22.9716 10.3272Z"
            fill="inherit"
          />
          <path
            d="M21.5636 22.6426C22.7011 22.6426 23.6233 21.7124 23.6233 20.5651C23.6233 19.4177 22.7011 18.4875 21.5636 18.4875C20.4261 18.4875 19.5039 19.4177 19.5039 20.5651C19.5039 21.7124 20.4261 22.6426 21.5636 22.6426Z"
            fill="inherit"
          />
          <path
            d="M22.9716 16.2035V16.2067L22.6075 16.6827C22.4434 16.8971 22.345 17.1351 22.3231 17.3794C22.309 17.5418 22.3074 17.7057 22.3215 17.8681C22.3418 18.1171 22.4418 18.3583 22.6091 18.5742L22.9716 19.044V19.0471L21.5667 20.5729L20.1556 19.0471V19.044L20.5087 18.5916C20.6791 18.3741 20.7807 18.1297 20.801 17.8791C20.8166 17.7073 20.815 17.5339 20.7978 17.3621C20.7744 17.1178 20.6759 16.8798 20.5103 16.6654L20.154 16.2051V16.202L21.5651 14.6935L22.9701 16.202L22.9716 16.2035Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M8.82104 10.8994C9.95858 10.8994 10.8807 9.96928 10.8807 8.8219C10.8807 7.67452 9.95858 6.74438 8.82104 6.74438C7.68351 6.74438 6.76135 7.67452 6.76135 8.8219C6.76135 9.96928 7.68351 10.8994 8.82104 10.8994Z"
            fill="inherit"
          />
          <path
            d="M8.82104 16.7678C9.95858 16.7678 10.8807 15.8377 10.8807 14.6903C10.8807 13.5429 9.95858 12.6128 8.82104 12.6128C7.68351 12.6128 6.76135 13.5429 6.76135 14.6903C6.76135 15.8377 7.68351 16.7678 8.82104 16.7678Z"
            fill="inherit"
          />
          <path
            d="M10.2291 10.3272V10.3303L9.86494 10.8064C9.70086 11.0208 9.6024 11.2588 9.58052 11.5031C9.56646 11.6654 9.56489 11.8294 9.57896 11.9917C9.59927 12.2408 9.69929 12.4819 9.8665 12.6979L10.2291 13.1676V13.1708L8.82416 14.6966L7.413 13.1708V13.1676L7.76618 12.7152C7.93652 12.4977 8.03809 12.2534 8.05841 12.0028C8.07404 11.831 8.07248 11.6576 8.05529 11.4857C8.03185 11.2414 7.93339 11.0034 7.76774 10.789L7.41144 10.3288V10.3256L8.82259 8.81714L10.2275 10.3256L10.2291 10.3272Z"
            fill="inherit"
          />
          <path
            d="M8.82104 22.6426C9.95858 22.6426 10.8807 21.7124 10.8807 20.5651C10.8807 19.4177 9.95858 18.4875 8.82104 18.4875C7.68351 18.4875 6.76135 19.4177 6.76135 20.5651C6.76135 21.7124 7.68351 22.6426 8.82104 22.6426Z"
            fill="inherit"
          />
          <path
            d="M10.2291 16.2035V16.2067L9.86494 16.6827C9.70086 16.8971 9.6024 17.1351 9.58052 17.3794C9.56646 17.5418 9.56489 17.7057 9.57896 17.8681C9.59927 18.1171 9.69929 18.3583 9.8665 18.5742L10.2291 19.044V19.0471L8.82416 20.5729L7.413 19.0471V19.044L7.76618 18.5916C7.93652 18.3741 8.03809 18.1297 8.05841 17.8791C8.07404 17.7073 8.07248 17.5339 8.05529 17.3621C8.03185 17.1178 7.93339 16.8798 7.76774 16.6654L7.41144 16.2051V16.202L8.82259 14.6935L10.2275 16.202L10.2291 16.2035Z"
            fill="inherit"
          />
        </g>
        <path
          d="M2.456 16.8199C3.59353 16.8199 4.51569 15.8898 4.51569 14.7424C4.51569 13.5951 3.59353 12.6649 2.456 12.6649C1.31846 12.6649 0.396301 13.5951 0.396301 14.7424C0.396301 15.8898 1.31846 16.8199 2.456 16.8199Z"
          fill="inherit"
        />
        <path
          d="M78.9209 16.8278C80.0584 16.8278 80.9806 15.8976 80.9806 14.7502C80.9806 13.6029 80.0584 12.6727 78.9209 12.6727C77.7834 12.6727 76.8612 13.6029 76.8612 14.7502C76.8612 15.8976 77.7834 16.8278 78.9209 16.8278Z"
          fill="inherit"
        />
        <g opacity="0.7">
          <path
            d="M66.1627 10.8838C67.3003 10.8838 68.2224 9.95365 68.2224 8.80627C68.2224 7.65889 67.3003 6.72876 66.1627 6.72876C65.0252 6.72876 64.103 7.65889 64.103 8.80627C64.103 9.95365 65.0252 10.8838 66.1627 10.8838Z"
            fill="inherit"
          />
          <path
            d="M66.1642 5.00903C67.3017 5.00903 68.2239 4.0789 68.2239 2.93152C68.2239 1.78414 67.3017 0.854004 66.1642 0.854004C65.0266 0.854004 64.1045 1.78414 64.1045 2.93152C64.1045 4.0789 65.0266 5.00903 66.1642 5.00903Z"
            fill="inherit"
          />
          <path
            d="M67.5707 4.44475V4.4479L67.2066 4.92394C67.0425 5.13831 66.9441 5.37632 66.9222 5.62064C66.9081 5.783 66.9066 5.94693 66.9206 6.10928C66.941 6.35833 67.041 6.5995 67.2082 6.81545L67.5707 7.28517V7.28833L66.1658 8.81415L64.7547 7.28833V7.28517L65.1078 6.83279C65.2782 6.61526 65.3798 6.37094 65.4001 6.12032C65.4157 5.9485 65.4142 5.77512 65.397 5.6033C65.3735 5.35898 65.2751 5.12097 65.1094 4.9066L64.7531 4.44633V4.44318L66.1643 2.93469L67.5692 4.44318L67.5707 4.44475Z"
            fill="inherit"
          />
          <path
            d="M66.1627 16.7522C67.3003 16.7522 68.2224 15.8221 68.2224 14.6747C68.2224 13.5273 67.3003 12.5972 66.1627 12.5972C65.0252 12.5972 64.103 13.5273 64.103 14.6747C64.103 15.8221 65.0252 16.7522 66.1627 16.7522Z"
            fill="inherit"
          />
          <path
            d="M67.5707 10.3132V10.3163L67.2066 10.7923C67.0425 11.0067 66.9441 11.2447 66.9222 11.489C66.9081 11.6514 66.9066 11.8153 66.9206 11.9777C66.941 12.2267 67.041 12.4679 67.2082 12.6839L67.5707 13.1536V13.1567L66.1658 14.6826L64.7547 13.1567V13.1536L65.1078 12.7012C65.2782 12.4837 65.3798 12.2394 65.4001 11.9887C65.4157 11.8169 65.4142 11.6435 65.397 11.4717C65.3735 11.2274 65.2751 10.9894 65.1094 10.775L64.7531 10.3147V10.3116L66.1643 8.8031L67.5692 10.3116L67.5707 10.3132Z"
            fill="inherit"
          />
          <path
            d="M66.1627 22.6284C67.3003 22.6284 68.2224 21.6983 68.2224 20.5509C68.2224 19.4035 67.3003 18.4734 66.1627 18.4734C65.0252 18.4734 64.103 19.4035 64.103 20.5509C64.103 21.6983 65.0252 22.6284 66.1627 22.6284Z"
            fill="inherit"
          />
          <path
            d="M67.5707 16.1895V16.1927L67.2066 16.6687C67.0425 16.8831 66.9441 17.1211 66.9222 17.3654C66.9081 17.5277 66.9066 17.6917 66.9206 17.854C66.941 18.1031 67.041 18.3443 67.2082 18.5602L67.5707 19.0299V19.0331L66.1658 20.5589L64.7547 19.0331V19.0299L65.1078 18.5775C65.2782 18.36 65.3798 18.1157 65.4001 17.8651C65.4157 17.6933 65.4142 17.5199 65.397 17.3481C65.3735 17.1037 65.2751 16.8657 65.1094 16.6513L64.7531 16.1911V16.1879L66.1643 14.6794L67.5692 16.1879L67.5707 16.1895Z"
            fill="inherit"
          />
          <path
            d="M66.1627 28.5C67.3003 28.5 68.2224 27.5699 68.2224 26.4225C68.2224 25.2751 67.3003 24.345 66.1627 24.345C65.0252 24.345 64.103 25.2751 64.103 26.4225C64.103 27.5699 65.0252 28.5 66.1627 28.5Z"
            fill="inherit"
          />
          <path
            d="M67.5707 22.061V22.0641L67.2066 22.5401C67.0425 22.7545 66.9441 22.9925 66.9222 23.2369C66.9081 23.3992 66.9066 23.5631 66.9206 23.7255C66.941 23.9745 67.041 24.2157 67.2082 24.4317L67.5707 24.9014V24.9045L66.1658 26.4304L64.7547 24.9045V24.9014L65.1078 24.449C65.2782 24.2315 65.3798 23.9872 65.4001 23.7365C65.4157 23.5647 65.4142 23.3913 65.397 23.2195C65.3735 22.9752 65.2751 22.7372 65.1094 22.5228L64.7531 22.0625V22.0594L66.1643 20.5509L67.5692 22.0594L67.5707 22.061Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M72.5418 10.9073C73.6793 10.9073 74.6015 9.97721 74.6015 8.82983C74.6015 7.68245 73.6793 6.75232 72.5418 6.75232C71.4043 6.75232 70.4821 7.68245 70.4821 8.82983C70.4821 9.97721 71.4043 10.9073 72.5418 10.9073Z"
            fill="inherit"
          />
          <path
            d="M72.5418 16.7758C73.6793 16.7758 74.6015 15.8456 74.6015 14.6982C74.6015 13.5509 73.6793 12.6207 72.5418 12.6207C71.4043 12.6207 70.4821 13.5509 70.4821 14.6982C70.4821 15.8456 71.4043 16.7758 72.5418 16.7758Z"
            fill="inherit"
          />
          <path
            d="M73.9498 10.3367V10.3399L73.5857 10.8159C73.4216 11.0303 73.3232 11.2683 73.3013 11.5126C73.2872 11.675 73.2857 11.8389 73.2997 12.0012C73.32 12.2503 73.4201 12.4915 73.5873 12.7074L73.9498 13.1771V13.1803L72.5449 14.7061L71.1338 13.1803V13.1771L71.4869 12.7248C71.6573 12.5072 71.7589 12.2629 71.7792 12.0123C71.7948 11.8405 71.7933 11.6671 71.7761 11.4953C71.7526 11.251 71.6542 11.0129 71.4885 10.7986L71.1322 10.3383V10.3351L72.5434 8.82666L73.9483 10.3351L73.9498 10.3367Z"
            fill="inherit"
          />
          <path
            d="M72.5418 22.6521C73.6793 22.6521 74.6015 21.722 74.6015 20.5746C74.6015 19.4272 73.6793 18.4971 72.5418 18.4971C71.4043 18.4971 70.4821 19.4272 70.4821 20.5746C70.4821 21.722 71.4043 22.6521 72.5418 22.6521Z"
            fill="inherit"
          />
          <path
            d="M73.9498 16.2115V16.2146L73.5857 16.6907C73.4216 16.905 73.3232 17.143 73.3013 17.3874C73.2872 17.5497 73.2857 17.7136 73.2997 17.876C73.32 18.1251 73.4201 18.3662 73.5873 18.5822L73.9498 19.0519V19.055L72.5449 20.5809L71.1338 19.055V19.0519L71.4869 18.5995C71.6573 18.382 71.7589 18.1377 71.7792 17.887C71.7948 17.7152 71.7933 17.5418 71.7761 17.37C71.7526 17.1257 71.6542 16.8877 71.4885 16.6733L71.1322 16.213V16.2099L72.5434 14.7014L73.9483 16.2099L73.9498 16.2115Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M59.7992 10.9073C60.9367 10.9073 61.8589 9.97721 61.8589 8.82983C61.8589 7.68245 60.9367 6.75232 59.7992 6.75232C58.6617 6.75232 57.7395 7.68245 57.7395 8.82983C57.7395 9.97721 58.6617 10.9073 59.7992 10.9073Z"
            fill="inherit"
          />
          <path
            d="M59.7992 16.7758C60.9367 16.7758 61.8589 15.8456 61.8589 14.6982C61.8589 13.5509 60.9367 12.6207 59.7992 12.6207C58.6617 12.6207 57.7395 13.5509 57.7395 14.6982C57.7395 15.8456 58.6617 16.7758 59.7992 16.7758Z"
            fill="inherit"
          />
          <path
            d="M61.2072 10.3367V10.3399L60.8431 10.8159C60.679 11.0303 60.5806 11.2683 60.5587 11.5126C60.5446 11.675 60.543 11.8389 60.5571 12.0012C60.5774 12.2503 60.6774 12.4915 60.8447 12.7074L61.2072 13.1771V13.1803L59.8023 14.7061L58.3912 13.1803V13.1771L58.7443 12.7248C58.9147 12.5072 59.0162 12.2629 59.0366 12.0123C59.0522 11.8405 59.0506 11.6671 59.0334 11.4953C59.01 11.251 58.9115 11.0129 58.7459 10.7986L58.3896 10.3383V10.3351L59.8007 8.82666L61.2057 10.3351L61.2072 10.3367Z"
            fill="inherit"
          />
          <path
            d="M59.7992 22.6521C60.9367 22.6521 61.8589 21.722 61.8589 20.5746C61.8589 19.4272 60.9367 18.4971 59.7992 18.4971C58.6617 18.4971 57.7395 19.4272 57.7395 20.5746C57.7395 21.722 58.6617 22.6521 59.7992 22.6521Z"
            fill="inherit"
          />
          <path
            d="M61.2072 16.2115V16.2146L60.8431 16.6907C60.679 16.905 60.5806 17.143 60.5587 17.3874C60.5446 17.5497 60.543 17.7136 60.5571 17.876C60.5774 18.1251 60.6774 18.3662 60.8447 18.5822L61.2072 19.0519V19.055L59.8023 20.5809L58.3912 19.055V19.0519L58.7443 18.5995C58.9147 18.382 59.0162 18.1377 59.0366 17.887C59.0522 17.7152 59.0506 17.5418 59.0334 17.37C59.01 17.1257 58.9115 16.8877 58.7459 16.6733L58.3896 16.213V16.2099L59.8007 14.7014L61.2057 16.2099L61.2072 16.2115Z"
            fill="inherit"
          />
        </g>
        <path
          d="M78.9381 16.8483C80.0756 16.8483 80.9978 15.9181 80.9978 14.7707C80.9978 13.6234 80.0756 12.6932 78.9381 12.6932C77.8006 12.6932 76.8784 13.6234 76.8784 14.7707C76.8784 15.9181 77.8006 16.8483 78.9381 16.8483Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_8361_25311">
          <rect width="81.375" height="28" fill="inherit" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

RegenerusLogoMobile.defaultProps = {
  fill: "white"
};
