import { useState } from "react";

import { useAppState } from "components/AppStateProvider";
import { AuthContext } from "components/AuthProvider";
import Box from "components/Box";
import Loading from "components/Loading";
import ProductQtyInput from "components/ProductQtyInput";
import { BasketContext, useBasketContext } from "contexts/BasketContext";
import { LOGIN_URL, getOrderUrl } from "core/urls";
import { sortBy } from "lodash";
import styled from "styled-components";
import Center from "tpo/Center";
import Currency from "tpo/Currency";
import { Error } from "tpo/InputWrapper";
import LinkWrapper from "tpo/LinkWrapper";
import ButtonV2 from "v2/Buttons";

import FloatingLabelInput from "../FloatingLabelInput";
import Group from "../Group";
import Modal from "../Modal";
import Spacer from "../Spacer";
import Stack from "../Stack";
import getClinicFees from "./utils/getClinicFees";
import groupItemsByName from "./utils/groupItemByName";

function BasketItem({
  count: initialCount,
  name,
  price,
  previousPrice,
  setBasketItemQty,
  "data-testid": dataTestId,
  className,
  py
}) {
  const { isSubmitting } = useBasketContext();

  const [count, setCount] = useState(initialCount);

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      alignItems={["unset", "unset", "center"]}
      gap={10}
      data-component-name="BasketItem"
      data-testid={dataTestId || "lineInBasket"}
      className={className}
      py={py}
    >
      <Group alignItems="center" gap={10} flexGrow={1}>
        <ProductQtyInput
          onChange={qty => {
            setCount(qty);
          }}
          value={count}
          disabled={isSubmitting}
          onBlur={() => {
            if (count !== "") {
              if (count !== initialCount) {
                setBasketItemQty(count);
              }
            } else {
              setCount(initialCount);
            }
          }}
        />
        <Box>
          <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} mr="auto" data-testid="name">
            {name}
          </Box>
          <ButtonV2
            letterSpacing="unset"
            fontSize={16}
            textTransform="none"
            variant="link"
            onClick={() => {
              setBasketItemQty(0);
            }}
            disabled={isSubmitting}
            size={null}
            data-component-name="RemoveButton"
          >
            Remove
          </ButtonV2>
        </Box>
      </Group>
      <Group alignItems="center" gap={10} justifyContent="flex-end" flexGrow={1}>
        {previousPrice && previousPrice > price && (
          <Currency
            fontSize={28}
            value={previousPrice}
            color="#e44c4b"
            strikethrough
            data-testid="previousPrice"
          />
        )}
        <Currency fontSize={28} value={price} data-testid="currentPrice" />
      </Group>
    </Box>
  );
}

function BasketItemWithClinic({
  count,
  name,
  price,
  previousPrice,
  clinicName,
  address,
  fees,
  setBasketItemQty,
  className,
  py
}) {
  return (
    <Stack
      className={className}
      gap={20}
      data-component-name="BasketItemWithClinic"
      data-testid="lineInBasket"
      py={py}
    >
      <BasketItem
        key={count}
        count={count}
        name={name}
        price={price}
        previousPrice={previousPrice}
        setBasketItemQty={setBasketItemQty}
        data-testid="BasketItem"
      />
      <Box>
        <Box fontFamily="gilroyBold" fontSize={16} data-testid="clinicName">
          {clinicName}
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={14} data-testid="clinicAddress">
          {address}
        </Box>
        <Group alignItems="center" justifyContent="space-between" data-testid="clinicFee">
          <Box fontFamily="gilroyBold" fontSize={16}>
            Clinic fee
          </Box>
          <Currency fontSize={18} value={fees} />
        </Group>
      </Box>
    </Stack>
  );
}

// Whereas this assumes only one clinic location
export function getPrice(lineSummary) {
  let count = lineSummary.count;
  let linePrice = lineSummary.linePrice;
  return (linePrice * count).toFixed(2);
}

function Discount() {
  const {
    openOrder,
    setDiscountCode,
    discountCode,
    discountCodeError,
    updateDiscount,
    removeDiscount
  } = useBasketContext();

  const hasError =
    (openOrder.discountMeetsRequirements && discountCodeError) ||
    !openOrder.discountMeetsRequirements;

  const discountIsApplied =
    openOrder.discountCode !== "" && openOrder.discountCode === discountCode.toLowerCase();

  return (
    <>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Discount
      </Box>
      <Spacer py={[2, 2, 15]} />
      <Box>
        <FloatingLabelInput
          name="discountCode"
          label="Enter Discount"
          onChange={e => setDiscountCode(e.target.value)}
          rightIcon={
            discountIsApplied ? (
              <ButtonV2
                // NB: successfully removing the discount will result in the local state being reset to ""
                onClick={removeDiscount}
                sx={{
                  color: "red",
                  fontSize: 18,
                  textTransform: "unset",
                  letterSpacing: "unset",
                  fontFamily: "gilroyMedium",
                  p: 0
                }}
                data-testid="removeDiscountCode"
              >
                Remove
              </ButtonV2>
            ) : (
              <ButtonV2
                onClick={updateDiscount}
                sx={{
                  color: "green",
                  fontSize: 18,
                  textTransform: "unset",
                  letterSpacing: "unset",
                  fontFamily: "gilroyMedium",
                  p: 0
                }}
                disabled={discountCode === ""}
                data-testid="applyDiscountCode"
              >
                Apply
              </ButtonV2>
            )
          }
          value={discountCode}
          editable={!discountIsApplied}
        />
      </Box>
      {hasError && (
        <Error
          data-testid="discountCodeError"
          error={
            openOrder.discountMeetsRequirements
              ? discountCodeError
              : "Basket does not meet discount requirements."
          }
        />
      )}
    </>
  );
}

const LineItemStack = styled(Stack)`
  .BasketItemUI {
    border-bottom: 1px solid ${({ theme }) => theme.colors.haze};
  }
`;

export function UserBasketSummaryContent({ order, beforeTotals, afterTotals, footer }) {
  const { discountCode, setBasketItemQty } = useBasketContext();

  const allOrderItems = sortBy([...order.testItems, ...order.supplementItems], ["created"]);
  const orderItemsByName = groupItemsByName(allOrderItems);

  return (
    <>
      <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Basket summary
      </Box>
      <LineItemStack>
        {Object.keys(orderItemsByName).map(orderItem => {
          const lineSummary = orderItemsByName[orderItem];
          let clinic = null;
          if (lineSummary.clinicBookings.length > 0) {
            clinic = lineSummary.clinicBookings[0].location;
          }
          return clinic ? (
            <BasketItemWithClinic
              key={`${orderItem}.${lineSummary.count}`}
              name={lineSummary.nameInBasket}
              price={getPrice(lineSummary)}
              previousPrice={
                lineSummary.consumerFullPrice === undefined
                  ? undefined
                  : lineSummary.consumerFullPrice * lineSummary["count"]
              }
              clinicName={clinic.name || clinic.clinic?.name || "Clinic"}
              address={clinic.address}
              fees={getClinicFees(lineSummary.clinicBookings)}
              count={lineSummary.count}
              setBasketItemQty={qty => {
                setBasketItemQty({
                  clinicLocationId: clinic?.id,
                  compositeId: lineSummary.compositeId,
                  qty
                });
              }}
              className="BasketItemUI"
              py={20}
            />
          ) : (
            <BasketItem
              key={`${orderItem}.${lineSummary.count}`}
              name={lineSummary.nameInBasket}
              price={getPrice(lineSummary)}
              previousPrice={
                lineSummary.consumerFullPrice === undefined
                  ? undefined
                  : lineSummary.consumerFullPrice * lineSummary["count"]
              }
              count={lineSummary.count}
              setBasketItemQty={qty => {
                setBasketItemQty({
                  clinicLocationId: clinic?.id,
                  compositeId: lineSummary.compositeId,
                  qty
                });
              }}
              className="BasketItemUI"
              py={20}
            />
          );
        })}
      </LineItemStack>
      {discountCode !== undefined && (
        // i.e. wait for discount code state to be initialised
        <Box>
          <Discount />
        </Box>
      )}
      <Stack alignItems="flex-end" gap={20}>
        {beforeTotals}
        {afterTotals}
      </Stack>
      {footer}
    </>
  );
}

export function UserBasketSummary({ footer, beforeTotals, afterTotals }) {
  const { orderError, orderLoading, openOrder } = useBasketContext();

  if (orderError) return orderError;

  if (orderLoading)
    return (
      <Center>
        <Loading />
      </Center>
    );

  if (!openOrder || (openOrder.testItems.length === 0 && openOrder.supplementItems.length === 0))
    return (
      <>
        <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
          Basket summary
        </Box>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Basket empty - add products to continue
        </Box>
      </>
    );

  return (
    <UserBasketSummaryContent
      order={openOrder}
      footer={footer}
      beforeTotals={beforeTotals}
      afterTotals={afterTotals}
    />
  );
}

export default function Basket() {
  const { basketOpen, setBasketOpen } = useAppState();

  return (
    <Modal
      maxWidth={"min(calc(100% - 20px - 5.5vw), 1020px)"}
      closeButton
      headerProps={{
        p: [20, 20, 40]
      }}
      bg="white"
      show={basketOpen}
      mode={[
        "fullScreen",
        "fullScreen",
        {
          topRightPosition: {
            x: [20, 20, "max(calc((100% - 1538px) / 2), 20px)"],
            y: 104
          }
        }
      ]}
      close={() => setBasketOpen(false)}
      maxHeight={[null, null, "calc(100vh - 124px)"]}
      // 80px is height from top, 20px gap to bottom
      borderRadius={[0, 0, 5]}
      data-component-name={"ConsumerBasket"}
    >
      <Box maxWidth={760} mx="auto" pb={[60, 60, 120]} px={[20, 20, 40]}>
        <Stack gap={[20, 20, 40]}>
          <UserBasketSummary
            afterTotals={
              <BasketContext.Consumer>
                {({ openOrder }) =>
                  openOrder === undefined ? null : (
                    <Currency
                      prefix="TOTAL"
                      value={openOrder.discountedTotal.toFixed(2)}
                      fontSize={44}
                      data-testid="BasketTotal"
                    />
                  )
                }
              </BasketContext.Consumer>
            }
            footer={
              <AuthContext.Consumer>
                {({ user }) => {
                  return user ? (
                    <Box
                      display="flex"
                      flexDirection={["column", "column", "row"]}
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      gap={20}
                    >
                      <ButtonV2
                        color="dark"
                        onClick={() => setBasketOpen(false)}
                        size={["sm", "sm", "md"]}
                      >
                        continue shopping
                      </ButtonV2>
                      <BasketContext.Consumer>
                        {({ openOrder }) => (
                          <ButtonV2
                            as={LinkWrapper}
                            color="green"
                            to={getOrderUrl(openOrder.id)}
                            onClick={() => setBasketOpen(false)}
                            size={["sm", "sm", "md"]}
                          >
                            proceed to checkout
                          </ButtonV2>
                        )}
                      </BasketContext.Consumer>
                    </Box>
                  ) : (
                    <Stack gap={20} pt={20}>
                      <Box>
                        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
                          Choose checkout option
                        </Box>
                        <Spacer py={2} />
                        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                          We can see that you're not currently logged in. If you have an account,
                          please sign in to continue your purchase, otherwise you can checkout as a
                          guest now and create an account later
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection={["column", "column", "row"]}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        gap={20}
                      >
                        <BasketContext.Consumer>
                          {({ openOrder }) => (
                            <ButtonV2
                              as={LinkWrapper}
                              color="dark"
                              to={getOrderUrl(openOrder.id)}
                              onClick={() => setBasketOpen(false)}
                              size={["sm", "sm", "md"]}
                            >
                              check out as guest
                            </ButtonV2>
                          )}
                        </BasketContext.Consumer>
                        <ButtonV2
                          as={LinkWrapper}
                          color="green"
                          to={LOGIN_URL}
                          onClick={() => setBasketOpen(false)}
                          size={["sm", "sm", "md"]}
                        >
                          sign in to your account
                        </ButtonV2>
                      </Box>
                    </Stack>
                  );
                }}
              </AuthContext.Consumer>
            }
          />
        </Stack>
      </Box>
    </Modal>
  );
}
