export default function RegenerusLogoInline({ fill, style }) {
  return (
    <svg
      width="257"
      height="26"
      viewBox="0 0 257 26"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_8361_28367)">
        <path
          d="M43.7846 13.6111C44.7237 13.6111 45.485 12.848 45.485 11.9067C45.485 10.9655 44.7237 10.2024 43.7846 10.2024C42.8456 10.2024 42.0843 10.9655 42.0843 11.9067C42.0843 12.848 42.8456 13.6111 43.7846 13.6111Z"
          fill="inherit"
        />
        <g opacity="0.7">
          <path
            d="M33.2523 8.73461C34.1914 8.73461 34.9526 7.97155 34.9526 7.03027C34.9526 6.08899 34.1914 5.32593 33.2523 5.32593C32.3132 5.32593 31.5519 6.08899 31.5519 7.03027C31.5519 7.97155 32.3132 8.73461 33.2523 8.73461Z"
            fill="inherit"
          />
          <path
            d="M33.2535 3.91515C34.1926 3.91515 34.9539 3.15209 34.9539 2.21081C34.9539 1.26953 34.1926 0.50647 33.2535 0.50647C32.3144 0.50647 31.5532 1.26953 31.5532 2.21081C31.5532 3.15209 32.3144 3.91515 33.2535 3.91515Z"
            fill="inherit"
          />
          <path
            d="M34.4159 3.45219V3.45478L34.1154 3.8453C33.9799 4.02117 33.8986 4.21643 33.8806 4.41687C33.869 4.55006 33.8677 4.68454 33.8793 4.81773C33.896 5.02205 33.9786 5.2199 34.1167 5.39706L34.4159 5.78241V5.78499L33.2562 7.03674L32.0912 5.78499V5.78241L32.3828 5.41128C32.5234 5.23283 32.6072 5.03239 32.624 4.82679C32.6369 4.68584 32.6356 4.54359 32.6214 4.40264C32.6021 4.20221 32.5208 4.00695 32.384 3.83108L32.0899 3.45349V3.4509L33.2549 2.21338L34.4147 3.4509L34.4159 3.45219Z"
            fill="inherit"
          />
          <path
            d="M33.2523 13.5489C34.1914 13.5489 34.9526 12.7859 34.9526 11.8446C34.9526 10.9033 34.1914 10.1403 33.2523 10.1403C32.3132 10.1403 31.5519 10.9033 31.5519 11.8446C31.5519 12.7859 32.3132 13.5489 33.2523 13.5489Z"
            fill="inherit"
          />
          <path
            d="M34.4159 8.26652V8.26911L34.1154 8.65963C33.9799 8.8355 33.8986 9.03076 33.8806 9.2312C33.869 9.36439 33.8677 9.49887 33.8793 9.63206C33.896 9.83638 33.9786 10.0342 34.1167 10.2114L34.4159 10.5967V10.5993L33.2562 11.8511L32.0912 10.5993V10.5967L32.3828 10.2256C32.5234 10.0472 32.6072 9.84672 32.624 9.64112C32.6369 9.50017 32.6356 9.35792 32.6214 9.21697C32.6021 9.01654 32.5208 8.82127 32.384 8.64541L32.0899 8.26781V8.26523L33.2549 7.02771L34.4147 8.26523L34.4159 8.26652Z"
            fill="inherit"
          />
          <path
            d="M33.2523 18.3697C34.1914 18.3697 34.9526 17.6067 34.9526 16.6654C34.9526 15.7241 34.1914 14.9611 33.2523 14.9611C32.3132 14.9611 31.5519 15.7241 31.5519 16.6654C31.5519 17.6067 32.3132 18.3697 33.2523 18.3697Z"
            fill="inherit"
          />
          <path
            d="M34.4159 13.0873V13.0899L34.1154 13.4804C33.9799 13.6563 33.8986 13.8516 33.8806 14.052C33.869 14.1852 33.8677 14.3197 33.8793 14.4529C33.896 14.6572 33.9786 14.855 34.1167 15.0322L34.4159 15.4175V15.4201L33.2562 16.6719L32.0912 15.4201V15.4175L32.3828 15.0464C32.5234 14.868 32.6072 14.6675 32.624 14.4619C32.6369 14.321 32.6356 14.1787 32.6214 14.0378C32.6021 13.8373 32.5208 13.6421 32.384 13.4662L32.0899 13.0886V13.086L33.2549 11.8485L34.4147 13.086L34.4159 13.0873Z"
            fill="inherit"
          />
          <path
            d="M33.2523 23.1866C34.1914 23.1866 34.9526 22.4236 34.9526 21.4823C34.9526 20.541 34.1914 19.778 33.2523 19.778C32.3132 19.778 31.5519 20.541 31.5519 21.4823C31.5519 22.4236 32.3132 23.1866 33.2523 23.1866Z"
            fill="inherit"
          />
          <path
            d="M34.4159 17.9042V17.9068L34.1154 18.2973C33.9799 18.4732 33.8986 18.6685 33.8806 18.8689C33.869 19.0021 33.8677 19.1366 33.8793 19.2698C33.896 19.4741 33.9786 19.6719 34.1167 19.8491L34.4159 20.2344V20.237L33.2562 21.4888L32.0912 20.237V20.2344L32.3828 19.8633C32.5234 19.6849 32.6072 19.4844 32.624 19.2788C32.6369 19.1379 32.6356 18.9956 32.6214 18.8547C32.6021 18.6542 32.5208 18.459 32.384 18.2831L32.0899 17.9055V17.9029L33.2549 16.6654L34.4147 17.9029L34.4159 17.9042Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M38.5185 8.75402C39.4576 8.75402 40.2188 7.99096 40.2188 7.04968C40.2188 6.1084 39.4576 5.34534 38.5185 5.34534C37.5794 5.34534 36.8181 6.1084 36.8181 7.04968C36.8181 7.99096 37.5794 8.75402 38.5185 8.75402Z"
            fill="inherit"
          />
          <path
            d="M38.5185 13.5683C39.4575 13.5683 40.2188 12.8053 40.2188 11.864C40.2188 10.9227 39.4575 10.1597 38.5185 10.1597C37.5794 10.1597 36.8181 10.9227 36.8181 11.864C36.8181 12.8053 37.5794 13.5683 38.5185 13.5683Z"
            fill="inherit"
          />
          <path
            d="M39.6821 8.28593V8.28852L39.3815 8.67904C39.2461 8.85491 39.1648 9.05017 39.1467 9.2506C39.1351 9.3838 39.1338 9.51828 39.1454 9.65147C39.1622 9.85579 39.2448 10.0536 39.3828 10.2308L39.6821 10.6161V10.6187L38.5223 11.8705L37.3574 10.6187V10.6161L37.6489 10.245C37.7895 10.0666 37.8734 9.86613 37.8902 9.66053C37.9031 9.51958 37.9018 9.37733 37.8876 9.23638C37.8682 9.03595 37.787 8.84068 37.6502 8.66482L37.3561 8.28723V8.28464L38.521 7.04712L39.6808 8.28464L39.6821 8.28593Z"
            fill="inherit"
          />
          <path
            d="M38.5185 18.3892C39.4575 18.3892 40.2188 17.6261 40.2188 16.6848C40.2188 15.7435 39.4575 14.9805 38.5185 14.9805C37.5794 14.9805 36.8181 15.7435 36.8181 16.6848C36.8181 17.6261 37.5794 18.3892 38.5185 18.3892Z"
            fill="inherit"
          />
          <path
            d="M39.6821 13.1054V13.108L39.3815 13.4985C39.2461 13.6744 39.1648 13.8696 39.1467 14.0701C39.1351 14.2033 39.1338 14.3377 39.1454 14.4709C39.1622 14.6752 39.2448 14.8731 39.3828 15.0503L39.6821 15.4356V15.4382L38.5223 16.6899L37.3574 15.4382V15.4356L37.6489 15.0645C37.7895 14.886 37.8734 14.6856 37.8902 14.48C37.9031 14.339 37.9018 14.1968 37.8876 14.0558C37.8682 13.8554 37.787 13.6601 37.6502 13.4843L37.3561 13.1067V13.1041L38.521 11.8666L39.6808 13.1041L39.6821 13.1054Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M27.999 8.75402C28.9381 8.75402 29.6993 7.99096 29.6993 7.04968C29.6993 6.1084 28.9381 5.34534 27.999 5.34534C27.0599 5.34534 26.2986 6.1084 26.2986 7.04968C26.2986 7.99096 27.0599 8.75402 27.999 8.75402Z"
            fill="inherit"
          />
          <path
            d="M27.999 13.5683C28.9381 13.5683 29.6993 12.8053 29.6993 11.864C29.6993 10.9227 28.9381 10.1597 27.999 10.1597C27.0599 10.1597 26.2986 10.9227 26.2986 11.864C26.2986 12.8053 27.0599 13.5683 27.999 13.5683Z"
            fill="inherit"
          />
          <path
            d="M29.1614 8.28593V8.28852L28.8608 8.67904C28.7253 8.85491 28.644 9.05017 28.626 9.2506C28.6144 9.3838 28.6131 9.51828 28.6247 9.65147C28.6415 9.85579 28.724 10.0536 28.8621 10.2308L29.1614 10.6161V10.6187L28.0016 11.8705L26.8366 10.6187V10.6161L27.1282 10.245C27.2688 10.0666 27.3527 9.86613 27.3694 9.66053C27.3823 9.51958 27.381 9.37733 27.3668 9.23638C27.3475 9.03595 27.2662 8.84068 27.1295 8.66482L26.8353 8.28723V8.28464L28.0003 7.04712L29.1601 8.28464L29.1614 8.28593Z"
            fill="inherit"
          />
          <path
            d="M27.999 18.3892C28.9381 18.3892 29.6993 17.6261 29.6993 16.6848C29.6993 15.7435 28.9381 14.9805 27.999 14.9805C27.0599 14.9805 26.2986 15.7435 26.2986 16.6848C26.2986 17.6261 27.0599 18.3892 27.999 18.3892Z"
            fill="inherit"
          />
          <path
            d="M29.1614 13.1054V13.108L28.8608 13.4985C28.7253 13.6744 28.644 13.8696 28.626 14.0701C28.6144 14.2033 28.6131 14.3377 28.6247 14.4709C28.6415 14.6752 28.724 14.8731 28.8621 15.0503L29.1614 15.4356V15.4382L28.0016 16.6899L26.8366 15.4382V15.4356L27.1282 15.0645C27.2688 14.886 27.3527 14.6856 27.3694 14.48C27.3823 14.339 27.381 14.1968 27.3668 14.0558C27.3475 13.8554 27.2662 13.6601 27.1295 13.4843L26.8353 13.1067V13.1041L28.0003 11.8666L29.1601 13.1041L29.1614 13.1054Z"
            fill="inherit"
          />
        </g>
        <path
          d="M22.7444 13.6111C23.6835 13.6111 24.4448 12.848 24.4448 11.9067C24.4448 10.9655 23.6835 10.2024 22.7444 10.2024C21.8053 10.2024 21.0441 10.9655 21.0441 11.9067C21.0441 12.848 21.8053 13.6111 22.7444 13.6111Z"
          fill="inherit"
        />
        <g opacity="0.7">
          <path
            d="M12.2082 8.72814C13.1473 8.72814 13.9086 7.96508 13.9086 7.0238C13.9086 6.08252 13.1473 5.31946 12.2082 5.31946C11.2691 5.31946 10.5079 6.08252 10.5079 7.0238C10.5079 7.96508 11.2691 8.72814 12.2082 8.72814Z"
            fill="inherit"
          />
          <path
            d="M12.2095 3.90868C13.1486 3.90868 13.9099 3.14562 13.9099 2.20434C13.9099 1.26306 13.1486 0.5 12.2095 0.5C11.2704 0.5 10.5092 1.26306 10.5092 2.20434C10.5092 3.14562 11.2704 3.90868 12.2095 3.90868Z"
            fill="inherit"
          />
          <path
            d="M13.3706 3.44572V3.44831L13.07 3.83883C12.9346 4.0147 12.8533 4.20996 12.8352 4.41039C12.8236 4.54359 12.8223 4.67807 12.8339 4.81126C12.8507 5.01558 12.9333 5.21343 13.0713 5.39058L13.3706 5.77594V5.77852L12.2108 7.03027L11.0458 5.77852V5.77594L11.3374 5.40481C11.478 5.22636 11.5619 5.02592 11.5787 4.82032C11.5916 4.67936 11.5903 4.53712 11.5761 4.39617C11.5567 4.19574 11.4754 4.00047 11.3387 3.82461L11.0446 3.44702V3.44443L12.2095 2.20691L13.3693 3.44443L13.3706 3.44572Z"
            fill="inherit"
          />
          <path
            d="M12.2082 13.5425C13.1473 13.5425 13.9086 12.7794 13.9086 11.8381C13.9086 10.8968 13.1473 10.1338 12.2082 10.1338C11.2691 10.1338 10.5079 10.8968 10.5079 11.8381C10.5079 12.7794 11.2691 13.5425 12.2082 13.5425Z"
            fill="inherit"
          />
          <path
            d="M13.3706 8.26005V8.26264L13.07 8.65316C12.9346 8.82903 12.8533 9.02429 12.8352 9.22473C12.8236 9.35792 12.8223 9.4924 12.8339 9.62559C12.8507 9.82991 12.9333 10.0278 13.0713 10.2049L13.3706 10.5903V10.5929L12.2108 11.8446L11.0458 10.5929V10.5903L11.3374 10.2191C11.478 10.0407 11.5619 9.84025 11.5787 9.63465C11.5916 9.4937 11.5903 9.35145 11.5761 9.2105C11.5567 9.01007 11.4754 8.81481 11.3387 8.63894L11.0446 8.26135V8.25876L12.2095 7.02124L13.3693 8.25876L13.3706 8.26005Z"
            fill="inherit"
          />
          <path
            d="M12.2082 18.3633C13.1473 18.3633 13.9086 17.6002 13.9086 16.6589C13.9086 15.7176 13.1473 14.9546 12.2082 14.9546C11.2691 14.9546 10.5079 15.7176 10.5079 16.6589C10.5079 17.6002 11.2691 18.3633 12.2082 18.3633Z"
            fill="inherit"
          />
          <path
            d="M13.3706 13.0795V13.0821L13.07 13.4726C12.9346 13.6485 12.8533 13.8438 12.8352 14.0442C12.8236 14.1774 12.8223 14.3119 12.8339 14.4451C12.8507 14.6494 12.9333 14.8472 13.0713 15.0244L13.3706 15.4097V15.4123L12.2108 16.6641L11.0458 15.4123V15.4097L11.3374 15.0386C11.478 14.8601 11.5619 14.6597 11.5787 14.4541C11.5916 14.3132 11.5903 14.1709 11.5761 14.03C11.5567 13.8295 11.4754 13.6343 11.3387 13.4584L11.0446 13.0808V13.0782L12.2095 11.8407L13.3693 13.0782L13.3706 13.0795Z"
            fill="inherit"
          />
          <path
            d="M12.2082 23.1802C13.1473 23.1802 13.9086 22.4171 13.9086 21.4758C13.9086 20.5345 13.1473 19.7715 12.2082 19.7715C11.2691 19.7715 10.5079 20.5345 10.5079 21.4758C10.5079 22.4171 11.2691 23.1802 12.2082 23.1802Z"
            fill="inherit"
          />
          <path
            d="M13.3706 17.8977V17.9003L13.07 18.2909C12.9346 18.4667 12.8533 18.662 12.8352 18.8624C12.8236 18.9956 12.8223 19.1301 12.8339 19.2633C12.8507 19.4676 12.9333 19.6655 13.0713 19.8426L13.3706 20.228V20.2305L12.2108 21.4823L11.0458 20.2305V20.228L11.3374 19.8568C11.478 19.6784 11.5619 19.4779 11.5787 19.2723C11.5916 19.1314 11.5903 18.9891 11.5761 18.8482C11.5567 18.6478 11.4754 18.4525 11.3387 18.2766L11.0446 17.899V17.8965L12.2095 16.6589L13.3693 17.8965L13.3706 17.8977Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M17.4744 8.74755C18.4135 8.74755 19.1748 7.98449 19.1748 7.04321C19.1748 6.10193 18.4135 5.33887 17.4744 5.33887C16.5353 5.33887 15.774 6.10193 15.774 7.04321C15.774 7.98449 16.5353 8.74755 17.4744 8.74755Z"
            fill="inherit"
          />
          <path
            d="M17.4744 13.5619C18.4135 13.5619 19.1748 12.7988 19.1748 11.8575C19.1748 10.9163 18.4135 10.1532 17.4744 10.1532C16.5353 10.1532 15.774 10.9163 15.774 11.8575C15.774 12.7988 16.5353 13.5619 17.4744 13.5619Z"
            fill="inherit"
          />
          <path
            d="M18.6368 8.27812V8.28071L18.3362 8.67123C18.2007 8.8471 18.1194 9.04236 18.1014 9.24279C18.0898 9.37598 18.0885 9.51047 18.1001 9.64366C18.1169 9.84798 18.1994 10.0458 18.3375 10.223L18.6368 10.6083V10.6109L17.477 11.8627L16.312 10.6109V10.6083L16.6036 10.2372C16.7442 10.0588 16.8281 9.85832 16.8448 9.65271C16.8577 9.51176 16.8564 9.36952 16.8423 9.22857C16.8229 9.02814 16.7416 8.83287 16.6049 8.65701L16.3107 8.27942V8.27683L17.4757 7.03931L18.6355 8.27683L18.6368 8.27812Z"
            fill="inherit"
          />
          <path
            d="M17.4744 18.3813C18.4135 18.3813 19.1748 17.6183 19.1748 16.677C19.1748 15.7357 18.4135 14.9727 17.4744 14.9727C16.5353 14.9727 15.774 15.7357 15.774 16.677C15.774 17.6183 16.5353 18.3813 17.4744 18.3813Z"
            fill="inherit"
          />
          <path
            d="M18.6368 13.0989V13.1015L18.3362 13.492C18.2007 13.6679 18.1194 13.8632 18.1014 14.0636C18.0898 14.1968 18.0885 14.3313 18.1001 14.4645C18.1169 14.6688 18.1994 14.8666 18.3375 15.0438L18.6368 15.4291V15.4317L17.477 16.6835L16.312 15.4317V15.4291L16.6036 15.058C16.7442 14.8796 16.8281 14.6791 16.8448 14.4735C16.8577 14.3326 16.8564 14.1903 16.8423 14.0494C16.8229 13.8489 16.7416 13.6537 16.6049 13.4778L16.3107 13.1002V13.0976L17.4757 11.8601L18.6355 13.0976L18.6368 13.0989Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M6.95493 8.74755C7.894 8.74755 8.65528 7.98449 8.65528 7.04321C8.65528 6.10193 7.894 5.33887 6.95493 5.33887C6.01585 5.33887 5.25458 6.10193 5.25458 7.04321C5.25458 7.98449 6.01585 8.74755 6.95493 8.74755Z"
            fill="inherit"
          />
          <path
            d="M6.95493 13.5619C7.894 13.5619 8.65528 12.7988 8.65528 11.8575C8.65528 10.9163 7.894 10.1532 6.95493 10.1532C6.01585 10.1532 5.25458 10.9163 5.25458 11.8575C5.25458 12.7988 6.01585 13.5619 6.95493 13.5619Z"
            fill="inherit"
          />
          <path
            d="M8.11731 8.27812V8.28071L7.81672 8.67123C7.68126 8.8471 7.59998 9.04236 7.58192 9.24279C7.57031 9.37598 7.56902 9.51047 7.58063 9.64366C7.5974 9.84798 7.67996 10.0458 7.818 10.223L8.11731 10.6083V10.6109L6.95751 11.8627L5.79255 10.6109V10.6083L6.08411 10.2372C6.22473 10.0588 6.30859 9.85832 6.32536 9.65271C6.33826 9.51176 6.33697 9.36952 6.32278 9.22857C6.30343 9.02814 6.22215 8.83287 6.0854 8.65701L5.79126 8.27942V8.27683L6.95622 7.03931L8.11602 8.27683L8.11731 8.27812Z"
            fill="inherit"
          />
          <path
            d="M6.95493 18.3813C7.894 18.3813 8.65528 17.6183 8.65528 16.677C8.65528 15.7357 7.894 14.9727 6.95493 14.9727C6.01585 14.9727 5.25458 15.7357 5.25458 16.677C5.25458 17.6183 6.01585 18.3813 6.95493 18.3813Z"
            fill="inherit"
          />
          <path
            d="M8.11731 13.0989V13.1015L7.81672 13.492C7.68126 13.6679 7.59998 13.8632 7.58192 14.0636C7.57031 14.1968 7.56902 14.3313 7.58063 14.4645C7.5974 14.6688 7.67996 14.8666 7.818 15.0438L8.11731 15.4291V15.4317L6.95751 16.6835L5.79255 15.4317V15.4291L6.08411 15.058C6.22473 14.8796 6.30859 14.6791 6.32536 14.4735C6.33826 14.3326 6.33697 14.1903 6.32278 14.0494C6.30343 13.8489 6.22215 13.6537 6.0854 13.4778L5.79126 13.1002V13.0976L6.95622 11.8601L8.11602 13.0976L8.11731 13.0989Z"
            fill="inherit"
          />
        </g>
        <path
          d="M1.70035 13.6045C2.63943 13.6045 3.4007 12.8414 3.4007 11.9001C3.4007 10.9589 2.63943 10.1958 1.70035 10.1958C0.761276 10.1958 0 10.9589 0 11.9001C0 12.8414 0.761276 13.6045 1.70035 13.6045Z"
          fill="inherit"
        />
        <path
          d="M64.8249 13.6111C65.7639 13.6111 66.5252 12.848 66.5252 11.9067C66.5252 10.9655 65.7639 10.2024 64.8249 10.2024C63.8858 10.2024 63.1245 10.9655 63.1245 11.9067C63.1245 12.848 63.8858 13.6111 64.8249 13.6111Z"
          fill="inherit"
        />
        <g opacity="0.7">
          <path
            d="M54.2925 8.73461C55.2316 8.73461 55.9929 7.97155 55.9929 7.03027C55.9929 6.08899 55.2316 5.32593 54.2925 5.32593C53.3534 5.32593 52.5922 6.08899 52.5922 7.03027C52.5922 7.97155 53.3534 8.73461 54.2925 8.73461Z"
            fill="inherit"
          />
          <path
            d="M54.2937 3.91515C55.2328 3.91515 55.9941 3.15209 55.9941 2.21081C55.9941 1.26953 55.2328 0.50647 54.2937 0.50647C53.3547 0.50647 52.5934 1.26953 52.5934 2.21081C52.5934 3.15209 53.3547 3.91515 54.2937 3.91515Z"
            fill="inherit"
          />
          <path
            d="M55.4549 3.45219V3.45478L55.1543 3.8453C55.0188 4.02117 54.9376 4.21643 54.9195 4.41687C54.9079 4.55006 54.9066 4.68454 54.9182 4.81773C54.935 5.02205 55.0175 5.2199 55.1556 5.39706L55.4549 5.78241V5.78499L54.2951 7.03674L53.1301 5.78499V5.78241L53.4217 5.41128C53.5623 5.23283 53.6462 5.03239 53.6629 4.82679C53.6758 4.68584 53.6746 4.54359 53.6604 4.40264C53.641 4.20221 53.5597 4.00695 53.423 3.83108L53.1288 3.45349V3.4509L54.2938 2.21338L55.4536 3.4509L55.4549 3.45219Z"
            fill="inherit"
          />
          <path
            d="M54.2925 13.5489C55.2316 13.5489 55.9929 12.7859 55.9929 11.8446C55.9929 10.9033 55.2316 10.1403 54.2925 10.1403C53.3534 10.1403 52.5922 10.9033 52.5922 11.8446C52.5922 12.7859 53.3534 13.5489 54.2925 13.5489Z"
            fill="inherit"
          />
          <path
            d="M55.4549 8.26652V8.26911L55.1543 8.65963C55.0188 8.8355 54.9376 9.03076 54.9195 9.2312C54.9079 9.36439 54.9066 9.49887 54.9182 9.63206C54.935 9.83638 55.0175 10.0342 55.1556 10.2114L55.4549 10.5967V10.5993L54.2951 11.8511L53.1301 10.5993V10.5967L53.4217 10.2256C53.5623 10.0472 53.6462 9.84672 53.6629 9.64112C53.6758 9.50017 53.6746 9.35792 53.6604 9.21697C53.641 9.01654 53.5597 8.82127 53.423 8.64541L53.1288 8.26781V8.26523L54.2938 7.02771L55.4536 8.26523L55.4549 8.26652Z"
            fill="inherit"
          />
          <path
            d="M54.2925 18.3697C55.2316 18.3697 55.9929 17.6067 55.9929 16.6654C55.9929 15.7241 55.2316 14.9611 54.2925 14.9611C53.3534 14.9611 52.5922 15.7241 52.5922 16.6654C52.5922 17.6067 53.3534 18.3697 54.2925 18.3697Z"
            fill="inherit"
          />
          <path
            d="M55.4549 13.0873V13.0899L55.1543 13.4804C55.0188 13.6563 54.9376 13.8516 54.9195 14.052C54.9079 14.1852 54.9066 14.3197 54.9182 14.4529C54.935 14.6572 55.0175 14.855 55.1556 15.0322L55.4549 15.4175V15.4201L54.2951 16.6719L53.1301 15.4201V15.4175L53.4217 15.0464C53.5623 14.868 53.6462 14.6675 53.6629 14.4619C53.6758 14.321 53.6746 14.1787 53.6604 14.0378C53.641 13.8373 53.5597 13.6421 53.423 13.4662L53.1288 13.0886V13.086L54.2938 11.8485L55.4536 13.086L55.4549 13.0873Z"
            fill="inherit"
          />
          <path
            d="M54.2925 23.1866C55.2316 23.1866 55.9929 22.4236 55.9929 21.4823C55.9929 20.541 55.2316 19.778 54.2925 19.778C53.3534 19.778 52.5922 20.541 52.5922 21.4823C52.5922 22.4236 53.3534 23.1866 54.2925 23.1866Z"
            fill="inherit"
          />
          <path
            d="M55.4549 17.9042V17.9068L55.1543 18.2973C55.0188 18.4732 54.9376 18.6685 54.9195 18.8689C54.9079 19.0021 54.9066 19.1366 54.9182 19.2698C54.935 19.4741 55.0175 19.6719 55.1556 19.8491L55.4549 20.2344V20.237L54.2951 21.4888L53.1301 20.237V20.2344L53.4217 19.8633C53.5623 19.6849 53.6462 19.4844 53.6629 19.2788C53.6758 19.1379 53.6746 18.9956 53.6604 18.8547C53.641 18.6542 53.5597 18.459 53.423 18.2831L53.1288 17.9055V17.9029L54.2938 16.6654L55.4536 17.9029L55.4549 17.9042Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M59.5587 8.75402C60.4978 8.75402 61.259 7.99096 61.259 7.04968C61.259 6.1084 60.4978 5.34534 59.5587 5.34534C58.6196 5.34534 57.8583 6.1084 57.8583 7.04968C57.8583 7.99096 58.6196 8.75402 59.5587 8.75402Z"
            fill="inherit"
          />
          <path
            d="M59.5587 13.5683C60.4978 13.5683 61.259 12.8053 61.259 11.864C61.259 10.9227 60.4978 10.1597 59.5587 10.1597C58.6196 10.1597 57.8583 10.9227 57.8583 11.864C57.8583 12.8053 58.6196 13.5683 59.5587 13.5683Z"
            fill="inherit"
          />
          <path
            d="M60.7211 8.28593V8.28852L60.4205 8.67904C60.285 8.85491 60.2037 9.05017 60.1857 9.2506C60.1741 9.3838 60.1728 9.51828 60.1844 9.65147C60.2012 9.85579 60.2837 10.0536 60.4218 10.2308L60.7211 10.6161V10.6187L59.5613 11.8705L58.3963 10.6187V10.6161L58.6879 10.245C58.8285 10.0666 58.9124 9.86613 58.9291 9.66053C58.942 9.51958 58.9407 9.37733 58.9265 9.23638C58.9072 9.03595 58.8259 8.84068 58.6892 8.66482L58.395 8.28723V8.28464L59.56 7.04712L60.7198 8.28464L60.7211 8.28593Z"
            fill="inherit"
          />
          <path
            d="M59.5587 18.3892C60.4978 18.3892 61.259 17.6261 61.259 16.6848C61.259 15.7435 60.4978 14.9805 59.5587 14.9805C58.6196 14.9805 57.8583 15.7435 57.8583 16.6848C57.8583 17.6261 58.6196 18.3892 59.5587 18.3892Z"
            fill="inherit"
          />
          <path
            d="M60.7211 13.1054V13.108L60.4205 13.4985C60.285 13.6744 60.2037 13.8696 60.1857 14.0701C60.1741 14.2033 60.1728 14.3377 60.1844 14.4709C60.2012 14.6752 60.2837 14.8731 60.4218 15.0503L60.7211 15.4356V15.4382L59.5613 16.6899L58.3963 15.4382V15.4356L58.6879 15.0645C58.8285 14.886 58.9124 14.6856 58.9291 14.48C58.942 14.339 58.9407 14.1968 58.9265 14.0558C58.9072 13.8554 58.8259 13.6601 58.6892 13.4843L58.395 13.1067V13.1041L59.56 11.8666L60.7198 13.1041L60.7211 13.1054Z"
            fill="inherit"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M49.0392 8.75402C49.9783 8.75402 50.7396 7.99096 50.7396 7.04968C50.7396 6.1084 49.9783 5.34534 49.0392 5.34534C48.1001 5.34534 47.3389 6.1084 47.3389 7.04968C47.3389 7.99096 48.1001 8.75402 49.0392 8.75402Z"
            fill="inherit"
          />
          <path
            d="M49.0392 13.5683C49.9783 13.5683 50.7396 12.8053 50.7396 11.864C50.7396 10.9227 49.9783 10.1597 49.0392 10.1597C48.1001 10.1597 47.3389 10.9227 47.3389 11.864C47.3389 12.8053 48.1001 13.5683 49.0392 13.5683Z"
            fill="inherit"
          />
          <path
            d="M50.2016 8.28593V8.28852L49.901 8.67904C49.7655 8.85491 49.6843 9.05017 49.6662 9.2506C49.6546 9.3838 49.6533 9.51828 49.6649 9.65147C49.6817 9.85579 49.7643 10.0536 49.9023 10.2308L50.2016 10.6161V10.6187L49.0418 11.8705L47.8768 10.6187V10.6161L48.1684 10.245C48.309 10.0666 48.3929 9.86613 48.4097 9.66053C48.4226 9.51958 48.4213 9.37733 48.4071 9.23638C48.3877 9.03595 48.3064 8.84068 48.1697 8.66482L47.8755 8.28723V8.28464L49.0405 7.04712L50.2003 8.28464L50.2016 8.28593Z"
            fill="inherit"
          />
          <path
            d="M49.0392 18.3892C49.9783 18.3892 50.7396 17.6261 50.7396 16.6848C50.7396 15.7435 49.9783 14.9805 49.0392 14.9805C48.1001 14.9805 47.3389 15.7435 47.3389 16.6848C47.3389 17.6261 48.1001 18.3892 49.0392 18.3892Z"
            fill="inherit"
          />
          <path
            d="M50.2016 13.1054V13.108L49.901 13.4985C49.7655 13.6744 49.6843 13.8696 49.6662 14.0701C49.6546 14.2033 49.6533 14.3377 49.6649 14.4709C49.6817 14.6752 49.7643 14.8731 49.9023 15.0503L50.2016 15.4356V15.4382L49.0418 16.6899L47.8768 15.4382V15.4356L48.1684 15.0645C48.309 14.886 48.3929 14.6856 48.4097 14.48C48.4226 14.339 48.4213 14.1968 48.4071 14.0558C48.3877 13.8554 48.3064 13.6601 48.1697 13.4843L47.8755 13.1067V13.1041L49.0405 11.8666L50.2003 13.1041L50.2016 13.1054Z"
            fill="inherit"
          />
        </g>
        <path
          d="M64.8391 13.6278C65.7782 13.6278 66.5394 12.8647 66.5394 11.9235C66.5394 10.9822 65.7782 10.2191 64.8391 10.2191C63.9 10.2191 63.1387 10.9822 63.1387 11.9235C63.1387 12.8647 63.9 13.6278 64.8391 13.6278Z"
          fill="inherit"
        />
        <path
          d="M76.4851 5.90527C77.2939 5.38284 78.326 5.12292 79.5787 5.12292V6.29062H79.2214C77.9519 6.29062 76.8966 6.66821 76.0555 7.42081C75.2143 8.1747 74.7937 9.40446 74.7937 11.1114V18.8895H73.7242V5.39577H74.795V8.17858C75.1124 7.18417 75.6762 6.4264 76.4851 5.90527Z"
          fill="inherit"
        />
        <path
          d="M94.549 12.5765H83.3871C83.4194 13.7856 83.6722 14.8045 84.1483 15.6334C84.6243 16.4623 85.2513 17.083 86.0279 17.4968C86.8046 17.9106 87.6548 18.1175 88.5746 18.1175C89.8441 18.1175 90.9071 17.7904 91.7637 17.1361C92.6203 16.4817 93.1596 15.5921 93.3815 14.4645H94.5C94.2459 15.8403 93.5957 16.9537 92.5481 17.8072C91.5005 18.6606 90.1756 19.0874 88.5733 19.0874C87.3671 19.0874 86.2885 18.8055 85.3365 18.243C84.3844 17.6805 83.6387 16.8723 83.0994 15.8197C82.5602 14.7683 82.2905 13.5373 82.2905 12.1291C82.2905 10.7209 82.5563 9.49497 83.0878 8.45142C83.6193 7.40787 84.3611 6.60872 85.3132 6.05396C86.2653 5.49921 87.3516 5.22119 88.5733 5.22119C89.795 5.22119 90.8852 5.49921 91.7986 6.05396C92.7107 6.60872 93.4047 7.3458 93.8808 8.26521C94.3568 9.18462 94.5942 10.1752 94.5942 11.2342C94.5942 11.8304 94.5787 12.2778 94.5465 12.5765H94.549ZM92.9184 8.6247C92.4655 7.7971 91.8514 7.17898 91.0735 6.77294C90.2956 6.3669 89.4557 6.16388 88.5501 6.16388C87.6444 6.16388 86.8085 6.3669 86.0396 6.77294C85.2694 7.17898 84.6437 7.79581 84.1599 8.6247C83.6761 9.45359 83.4181 10.4635 83.3858 11.6558H93.5247C93.5724 10.4635 93.3699 9.4523 92.9184 8.6247Z"
          fill="inherit"
        />
        <path
          d="M107.782 6.25316C108.734 6.9411 109.399 7.82301 109.781 8.90018V5.39581H110.852V19.3124C110.852 20.5551 110.59 21.6439 110.066 22.5802C109.543 23.5164 108.837 24.2367 107.948 24.7423C107.059 25.2479 106.068 25.5 104.973 25.5C103.355 25.5 102.014 25.094 100.95 24.2819C99.8874 23.4698 99.1895 22.3771 98.8567 21.0013H99.9274C100.245 22.0784 100.84 22.9358 101.713 23.5733C102.585 24.2108 103.673 24.5302 104.973 24.5302C105.877 24.5302 106.694 24.3194 107.424 23.8966C108.154 23.4737 108.728 22.8685 109.15 22.0823C109.571 21.2948 109.781 20.3715 109.781 19.3111V15.3593C109.401 16.4365 108.73 17.3262 107.77 18.0309C106.81 18.7357 105.655 19.0874 104.307 19.0874C103.101 19.0874 102.026 18.8016 101.082 18.2301C100.138 17.6585 99.3998 16.8464 98.8683 15.7951C98.3367 14.7438 98.071 13.5218 98.071 12.1304C98.071 10.739 98.3367 9.52088 98.8683 8.47733C99.3998 7.43378 100.138 6.63075 101.082 6.06695C102.026 5.50444 103.101 5.22253 104.307 5.22253C105.672 5.22253 106.831 5.56651 107.782 6.25445V6.25316ZM109.078 9.01139C108.61 8.10879 107.971 7.41309 107.162 6.92429C106.353 6.43549 105.449 6.19109 104.449 6.19109C103.449 6.19109 102.482 6.42773 101.688 6.89972C100.895 7.37171 100.276 8.05577 99.832 8.94932C99.3882 9.84417 99.165 10.9045 99.165 12.1304C99.165 13.3563 99.3869 14.4167 99.832 15.3115C100.276 16.2063 100.899 16.8943 101.7 17.374C102.501 17.8551 103.417 18.0943 104.449 18.0943C105.481 18.0943 106.357 17.8499 107.174 17.3611C107.991 16.8723 108.629 16.1766 109.09 15.274C109.55 14.3714 109.78 13.324 109.78 12.1304C109.78 10.9369 109.545 9.91529 109.078 9.01139Z"
          fill="inherit"
        />
        <path
          d="M127.25 12.5765H116.089C116.121 13.7856 116.374 14.8045 116.85 15.6334C117.326 16.4623 117.953 17.083 118.729 17.4968C119.506 17.9106 120.356 18.1175 121.276 18.1175C122.545 18.1175 123.609 17.7904 124.465 17.1361C125.322 16.4817 125.861 15.5921 126.083 14.4645H127.201C126.947 15.8403 126.297 16.9537 125.25 17.8072C124.202 18.6606 122.877 19.0874 121.275 19.0874C120.068 19.0874 118.99 18.8055 118.038 18.243C117.086 17.6805 116.34 16.8723 115.801 15.8197C115.262 14.7683 114.992 13.5373 114.992 12.1291C114.992 10.7209 115.258 9.49497 115.789 8.45142C116.321 7.40787 117.063 6.60872 118.015 6.05396C118.967 5.49921 120.053 5.22119 121.275 5.22119C122.496 5.22119 123.587 5.49921 124.5 6.05396C125.412 6.60872 126.106 7.3458 126.582 8.26521C127.058 9.18462 127.296 10.1752 127.296 11.2342C127.296 11.8304 127.28 12.2778 127.248 12.5765H127.25ZM125.62 8.6247C125.167 7.7971 124.553 7.17898 123.775 6.77294C122.997 6.3669 122.157 6.16388 121.252 6.16388C120.346 6.16388 119.51 6.3669 118.741 6.77294C117.971 7.17898 117.345 7.79581 116.861 8.6247C116.378 9.45359 116.119 10.4635 116.087 11.6558H126.226C126.274 10.4635 126.071 9.4523 125.62 8.6247Z"
          fill="inherit"
        />
        <path
          d="M141.03 6.60092C141.998 7.58628 142.481 9.0488 142.481 10.9872V18.8895H141.434V11.0622C141.434 9.45614 141.044 8.22508 140.268 7.37162C139.49 6.51816 138.434 6.09143 137.102 6.09143C135.769 6.09143 134.591 6.55566 133.758 7.48283C132.924 8.41129 132.508 9.78589 132.508 11.6079V18.8895H131.437V5.39572H132.508V8.25353C132.873 7.2436 133.479 6.46902 134.328 5.92979C135.177 5.39185 136.156 5.12158 137.267 5.12158C138.806 5.12158 140.06 5.61426 141.027 6.59962L141.03 6.60092Z"
          fill="inherit"
        />
        <path
          d="M158.761 12.5765H147.599C147.631 13.7856 147.884 14.8045 148.36 15.6334C148.836 16.4623 149.463 17.083 150.24 17.4968C151.017 17.9106 151.867 18.1175 152.787 18.1175C154.056 18.1175 155.119 17.7904 155.976 17.1361C156.832 16.4817 157.372 15.5921 157.594 14.4645H158.712C158.458 15.8403 157.808 16.9537 156.76 17.8072C155.713 18.6606 154.388 19.0874 152.785 19.0874C151.579 19.0874 150.501 18.8055 149.548 18.243C148.596 17.6805 147.851 16.8723 147.311 15.8197C146.772 14.7683 146.503 13.5373 146.503 12.1291C146.503 10.7209 146.768 9.49497 147.3 8.45142C147.831 7.40787 148.573 6.60872 149.525 6.05396C150.477 5.49921 151.564 5.22119 152.785 5.22119C154.007 5.22119 155.097 5.49921 156.011 6.05396C156.923 6.60872 157.617 7.3458 158.093 8.26521C158.569 9.18462 158.806 10.1752 158.806 11.2342C158.806 11.8304 158.791 12.2778 158.758 12.5765H158.761ZM157.13 8.6247C156.678 7.7971 156.063 7.17898 155.286 6.77294C154.508 6.3669 153.668 6.16388 152.762 6.16388C151.856 6.16388 151.02 6.3669 150.252 6.77294C149.481 7.17898 148.856 7.79581 148.372 8.6247C147.888 9.45359 147.63 10.4635 147.598 11.6558H157.737C157.784 10.4635 157.582 9.4523 157.13 8.6247Z"
          fill="inherit"
        />
        <path
          d="M165.711 5.90527C166.52 5.38284 167.552 5.12292 168.804 5.12292V6.29062H168.447C167.178 6.29062 166.122 6.66821 165.281 7.42081C164.44 8.1747 164.02 9.40446 164.02 11.1114V18.8895H162.949V5.39577H164.02V8.17858C164.337 7.18417 164.901 6.4264 165.71 5.90527H165.711Z"
          fill="inherit"
        />
        <path
          d="M183.109 5.39575V18.8895H182.038V15.9567C181.673 16.9847 181.066 17.7671 180.218 18.305C179.369 18.8443 178.398 19.1132 177.302 19.1132C175.763 19.1132 174.505 18.6205 173.53 17.6352C172.555 16.6498 172.066 15.1873 172.066 13.2489V5.39575H173.113V13.1739C173.113 14.7813 173.502 16.011 174.28 16.8645C175.056 17.7179 176.113 18.1447 177.446 18.1447C178.778 18.1447 179.956 17.6817 180.79 16.7533C181.623 15.8261 182.04 14.4502 182.04 12.6282V5.39704H183.11L183.109 5.39575Z"
          fill="inherit"
        />
        <path
          d="M188.689 18.0943C187.777 17.4322 187.265 16.4882 187.154 15.2611H188.249C188.328 16.1391 188.713 16.8348 189.403 17.3482C190.094 17.8615 191.034 18.1189 192.224 18.1189C193.207 18.1189 193.989 17.8577 194.568 17.3365C195.147 16.8141 195.436 16.1727 195.436 15.4111C195.436 14.8809 195.281 14.4464 194.972 14.1063C194.662 13.7675 194.277 13.5063 193.817 13.324C193.356 13.1416 192.731 12.9425 191.937 12.7278C190.985 12.4627 190.215 12.2015 189.628 11.9455C189.041 11.6895 188.54 11.3041 188.129 10.7894C187.716 10.2761 187.51 9.58813 187.51 8.7269C187.51 8.09715 187.692 7.51395 188.057 6.97472C188.422 6.43678 188.929 6.01005 189.58 5.69452C190.23 5.3803 190.968 5.22253 191.794 5.22253C193.142 5.22253 194.234 5.57426 195.067 6.27902C195.9 6.98377 196.348 7.9562 196.411 9.1989H195.341C195.293 8.30405 194.96 7.57602 194.341 7.01222C193.721 6.44971 192.865 6.16781 191.771 6.16781C190.851 6.16781 190.089 6.41609 189.486 6.91394C188.882 7.4105 188.582 8.01569 188.582 8.7282C188.582 9.35795 188.752 9.86744 189.094 10.2567C189.434 10.6459 189.855 10.9446 190.356 11.1515C190.855 11.3584 191.518 11.5782 192.342 11.8097C193.247 12.058 193.973 12.3024 194.52 12.5429C195.067 12.7834 195.531 13.1313 195.912 13.5865C196.293 14.0429 196.484 14.6507 196.484 15.4124C196.484 16.1081 196.3 16.7378 195.937 17.3003C195.571 17.8641 195.063 18.3025 194.413 18.618C193.763 18.9323 193.033 19.09 192.224 19.09C190.78 19.09 189.602 18.759 188.689 18.0956V18.0943Z"
          fill="inherit"
        />
        <path d="M209.336 0.5V18.8895H208.265V0.5H209.336Z" fill="inherit" />
        <path
          d="M214.299 8.47733C214.83 7.43378 215.568 6.63075 216.512 6.06695C217.457 5.50444 218.531 5.22253 219.738 5.22253C221.103 5.22253 222.26 5.57038 223.212 6.26609C224.164 6.96179 224.83 7.84887 225.212 8.92475V5.39581H226.282V18.8896H225.212V15.3361C224.831 16.4301 224.16 17.3288 223.2 18.0322C222.24 18.737 221.086 19.0887 219.738 19.0887C218.531 19.0887 217.457 18.8029 216.512 18.2314C215.568 17.6598 214.83 16.8477 214.299 15.7964C213.767 14.7451 213.501 13.5231 213.501 12.1317C213.501 10.7403 213.767 9.52218 214.299 8.47862V8.47733ZM224.508 9.01139C224.04 8.10879 223.402 7.41309 222.593 6.92429C221.784 6.43549 220.879 6.19109 219.88 6.19109C218.88 6.19109 217.912 6.42773 217.119 6.89972C216.325 7.37171 215.706 8.05577 215.262 8.94932C214.817 9.84417 214.595 10.9045 214.595 12.1304C214.595 13.3563 214.817 14.4167 215.262 15.3115C215.706 16.2063 216.329 16.8943 217.13 17.374C217.931 17.8551 218.847 18.0943 219.88 18.0943C220.912 18.0943 221.788 17.8499 222.604 17.3611C223.421 16.8723 224.059 16.1766 224.52 15.274C224.981 14.3714 225.21 13.324 225.21 12.1304C225.21 10.9369 224.975 9.91529 224.508 9.01139Z"
          fill="inherit"
        />
        <path
          d="M234.183 6.26475C235.135 5.56905 236.284 5.2212 237.634 5.2212C238.84 5.2212 239.915 5.5031 240.859 6.06561C241.803 6.62942 242.541 7.43245 243.073 8.476C243.604 9.51955 243.87 10.7377 243.87 12.1291C243.87 13.5205 243.604 14.7425 243.073 15.7938C242.541 16.8464 241.803 17.6572 240.859 18.2288C239.915 18.8003 238.84 19.0861 237.634 19.0861C236.284 19.0861 235.131 18.7382 234.171 18.0425C233.211 17.3468 232.541 16.452 232.16 15.3593V18.8882H231.089V0.5H232.16V8.94928C232.556 7.8553 233.231 6.96175 234.183 6.26605V6.26475ZM242.108 8.94799C241.664 8.05315 241.041 7.37038 240.24 6.89839C239.439 6.4264 238.514 6.18975 237.467 6.18975C236.421 6.18975 235.563 6.43415 234.754 6.92296C233.945 7.41176 233.311 8.10746 232.85 9.01006C232.39 9.91266 232.16 10.9523 232.16 12.1291C232.16 13.3058 232.39 14.3701 232.85 15.2727C233.311 16.1753 233.945 16.8723 234.754 17.3598C235.563 17.8486 236.468 18.093 237.467 18.093C238.467 18.093 239.439 17.8525 240.24 17.3727C241.041 16.893 241.664 16.205 242.108 15.3102C242.552 14.4153 242.775 13.355 242.775 12.1291C242.775 10.9032 242.553 9.84283 242.108 8.94799Z"
          fill="inherit"
        />
        <path
          d="M248.784 18.0943C247.872 17.4322 247.36 16.4882 247.249 15.2611H248.344C248.423 16.1391 248.809 16.8348 249.499 17.3482C250.189 17.8615 251.13 18.1189 252.319 18.1189C253.302 18.1189 254.084 17.8577 254.663 17.3365C255.242 16.8141 255.531 16.1727 255.531 15.4111C255.531 14.8809 255.377 14.4464 255.067 14.1063C254.757 13.7675 254.373 13.5063 253.912 13.324C253.452 13.1416 252.826 12.9425 252.033 12.7278C251.081 12.4627 250.31 12.2015 249.723 11.9455C249.136 11.6895 248.636 11.3041 248.224 10.7894C247.811 10.2761 247.605 9.58813 247.605 8.7269C247.605 8.09715 247.787 7.51395 248.152 6.97472C248.517 6.43678 249.024 6.01005 249.676 5.69452C250.326 5.3803 251.064 5.22253 251.889 5.22253C253.238 5.22253 254.329 5.57426 255.162 6.27902C255.996 6.98377 256.443 7.9562 256.507 9.1989H255.436C255.388 8.30405 255.055 7.57602 254.436 7.01222C253.817 6.44971 252.96 6.16781 251.866 6.16781C250.946 6.16781 250.184 6.41609 249.581 6.91394C248.978 7.4105 248.677 8.01569 248.677 8.7282C248.677 9.35795 248.847 9.86744 249.189 10.2567C249.53 10.6459 249.95 10.9446 250.451 11.1515C250.95 11.3584 251.613 11.5782 252.438 11.8097C253.342 12.058 254.068 12.3024 254.615 12.5429C255.162 12.7834 255.627 13.1313 256.007 13.5865C256.388 14.0429 256.579 14.6507 256.579 15.4124C256.579 16.1081 256.396 16.7378 256.032 17.3003C255.667 17.8641 255.159 18.3025 254.508 18.618C253.858 18.9323 253.128 19.09 252.319 19.09C250.875 19.09 249.698 18.759 248.784 18.0956V18.0943Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_8361_28367">
          <rect width="256.579" height="25" fill="inherit" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
