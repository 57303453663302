import { useContext } from "react";

import { Redirect, useParams } from "react-router-dom";

import DataLoader from "components/DataLoader";
import { ERROR_404_URL } from "core/urls";
import { PATIENT_TESTS } from "graphql/organisations/queries/patients";
import { ViewerContext } from "tpo/Viewer";

import Template from "../shared/Template";
import Tests from "./Tests";

function TestsTab() {
  const { id } = useParams();
  const { userId } = useContext(ViewerContext);

  return (
    <DataLoader
      query={PATIENT_TESTS}
      variables={{
        userId,
        userTestId: id,
        allTests: true,
        includeUnapproved: true
      }}
      render={({ userTests, userTest }) =>
        userTest ? (
          <Tests userTests={userTests} userTest={userTest} />
        ) : (
          <Redirect to={ERROR_404_URL} />
        )
      }
    />
  );
}

export default function Test() {
  return (
    <Template selectedTab="tests">
      <TestsTab />
    </Template>
  );
}
