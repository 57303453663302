import { useLocation } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import PoweredByOmnosBanner from "components/PoweredByOmnosBanner";
import PoweredByRegenerusBanner from "components/PoweredByRegenerusBanner";
import { HeaderProvider } from "contexts/HeaderContext";
import { EXTERNAL_KIT_REGISTRATION_COMPLETE_URL, EXTERNAL_KIT_REGISTRATION_URL } from "core/urls";
import usePartnerPageCheck from "hooks/partner/usePartnerPageCheck";

import Container from "./Container";
import PartnerHeader from "./PartnerHeader";
import UserHeader from "./UserHeader";

const EXCEPTIONS = [EXTERNAL_KIT_REGISTRATION_URL, EXTERNAL_KIT_REGISTRATION_COMPLETE_URL];

function useHeader() {
  const location = useLocation();
  return !EXCEPTIONS.includes(location.pathname);
}

export default function Header() {
  const isPartnerPage = usePartnerPageCheck();
  const { userInfo } = useAppState();
  const showHeader = useHeader();

  const user = userInfo?.user;
  const userSubmissions = userInfo?.userSubmissions;

  let header = <Container bg="blue" height={60} />;

  if (!showHeader) {
    return null;
  }

  if (userInfo) {
    header = isPartnerPage ? (
      <>
        <PoweredByOmnosBanner />
        <PartnerHeader user={user} userSubmissions={userSubmissions} />
      </>
    ) : (
      <>
        <PoweredByRegenerusBanner />
        <UserHeader user={user} userSubmissions={userSubmissions} />
      </>
    );
  }

  return <HeaderProvider>{header}</HeaderProvider>;
}
