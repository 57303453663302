import { useContext } from "react";

import { Route, Switch, useHistory, useParams } from "react-router-dom";

import Box from "components/Box";
import DataLoader from "components/DataLoader";
import { theme } from "core/theme";
import {
  CLIENT_MANAGEMENT_PLATFORM_URL_BASE,
  CLIENT_MANAGEMENT_USER_SUBSECTOR,
  getClientManagementUserSubsectorUrl,
  getTPOSubsectorUrl
} from "core/urls";
import { USER_THEME_QUERY } from "graphql/tpo/common/queries";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import { CollapseableText } from "tpo/CollapseableText";
import Menu, { Button as MenuButton } from "tpo/Menu";
import NothingToShow from "tpo/NothingToShow";
import {
  USER_SUBSECTOR_QUERY,
  UserSubsectorBody,
  UserSubsectorMenuItem,
  useUserSubsectorsMenu
} from "tpo/UserSubsector";
import { UserSubsectorScores } from "tpo/UserTheme";
import { ViewerContext } from "tpo/Viewer";

import Template from "./shared/Template";

function UserSubsectorMenu({ userSubsector: pageUserSubsector }) {
  const { userSubsectors, open, setOpen } = useUserSubsectorsMenu({
    themeSlug: "health"
  });
  const history = useHistory();
  const viewerCtx = useContext(ViewerContext);

  return (
    <Menu opened={open} onChange={setOpen} trigger="hover">
      <Menu.Target>
        <MenuButton
          opened={open}
          lineHeight="24px"
          paddingY="16px"
          paddingX="40px"
          maxWidth="100%"
          color="dark"
          sx={{
            color: "white"
          }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%"
            }}
          >
            {pageUserSubsector.name}
          </span>
        </MenuButton>
      </Menu.Target>
      {!!userSubsectors.length && (
        <Menu.Dropdown
          mt={2}
          py={2}
          zIndex={2}
          minWidth={310}
          maxWidth={310}
          height={500}
          maxHeight={500}
          overflowY="auto"
          bg="dark"
        >
          {userSubsectors.map(userSubsector => (
            <Menu.Item
              onClick={() =>
                history.push(
                  viewerCtx?.userId
                    ? getClientManagementUserSubsectorUrl(viewerCtx.userId, userSubsector.slug)
                    : getTPOSubsectorUrl("health", userSubsector.slug)
                )
              }
              key={userSubsector.id}
              selected={userSubsector.id === pageUserSubsector.id}
              py={16}
              selectedColor="rgba(255, 255, 255, 0.10)"
            >
              <UserSubsectorMenuItem userSubsector={userSubsector} color="white" />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}

function UserSubsector() {
  const { subsectorSlug } = useParams();
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={USER_SUBSECTOR_QUERY}
      variables={{ themeSlug: "health", subsectorSlug, userId: viewerContext?.userId }}
      render={({ userSubsector }) => (
        <UserSubsectorBody
          aboveMainChart={
            <>
              <Center>
                <UserSubsectorMenu userSubsector={userSubsector} />
              </Center>
              <Box py={[2, 2, 20]} />
            </>
          }
          userSubsector={userSubsector}
        />
      )}
    />
  );
}

export default function Wellness() {
  const { userId } = useContext(ViewerContext);

  return (
    <Template selectedTab="wellness">
      <Switch>
        <Route path={CLIENT_MANAGEMENT_PLATFORM_URL_BASE} exact>
          <DataLoader
            query={USER_THEME_QUERY}
            variables={{
              userId,
              themeSlug: "health"
            }}
            render={({ userTheme, userSubsectors }) =>
              userSubsectors.length ? (
                <>
                  <PanelBoxV2
                    maxWidth={760}
                    outer={{
                      pt: theme.spacing.section.pt,
                      px: 20,
                      pb: theme.spacing.section.pb,
                      bg: "white"
                    }}
                  >
                    <PanelBoxV2
                      maxWidth={760}
                      outer={{
                        px: 20
                      }}
                    >
                      <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                        Patient Health Scores
                      </Box>
                      <Box py={[2, 2, 20]} />
                      <CollapseableText fontSize={[14, 14, 16]}>
                        {`In this sector, we are looking at how various structures of the body, such as,
                      cells, tissues, organs, and systems are interlinked.

                      A healthy body is maintained by good nutrition, optimal detoxification, regular exercise, balanced hormones, avoiding harmful habits, making informed and responsible decisions about health, and seeking medical assistance when necessary.`}
                      </CollapseableText>
                    </PanelBoxV2>
                  </PanelBoxV2>
                  <UserSubsectorScores userTheme={userTheme} userSubsectors={userSubsectors} />
                </>
              ) : (
                <NothingToShow
                  jumbotron={
                    <>
                      <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                        No Results available
                      </Box>
                      <Box py={[2, 2, 20]} />
                      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                        Your patients wellness results will become available once they have taken a
                        functional test or completed a symptom questionnaire.
                      </Box>
                    </>
                  }
                />
              )
            }
          />
        </Route>
        <Route path={CLIENT_MANAGEMENT_USER_SUBSECTOR} component={UserSubsector} />
      </Switch>
    </Template>
  );
}
