import Box from "components/Box";
import { theme } from "core/theme";
import Stack from "tpo/Stack";

export default function PartnerDashboardBox({
  children,
  gap,
  mb,
  mt,
  maxWidth,
  borderRadius,
  pt,
  pb,
  mx
}) {
  return (
    <Box
      maxWidth={maxWidth}
      mx={mx}
      bg="white"
      px={[20, 20, 40]}
      pt={pt}
      pb={pb}
      borderRadius={borderRadius}
      width="100%"
      mt={mt}
      mb={mb}
    >
      <Stack gap={gap} mx="auto" maxWidth={760}>
        {children}
      </Stack>
    </Box>
  );
}

PartnerDashboardBox.defaultProps = {
  borderRadius: 5,
  gap: 40,
  maxWidth: 1020,
  pt: theme.spacing.section.pt,
  pb: theme.spacing.section.pb,
  mx: "auto"
};
