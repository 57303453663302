import { useContext } from "react";

import { Redirect } from "react-router-dom";

import { gql } from "@apollo/client";
import DataLoader from "components/DataLoader";
import { getClientManagementUserTestUrl } from "core/urls";
import { ViewerContext } from "tpo/Viewer";

import Template from "../shared/Template";
import NoTests from "./NoTests";

const GET_FIRST_USER_TEST = gql`
  query GetFirstUserTest($userId: ID!, $allTests: Boolean!, $includeUnapproved: Boolean!) {
    userTests(userId: $userId, allTests: $allTests, includeUnapproved: $includeUnapproved) {
      id
    }
  }
`;

export default function TestsRoute() {
  const { userId } = useContext(ViewerContext);

  return (
    <DataLoader
      query={GET_FIRST_USER_TEST}
      variables={{
        userId,
        allTests: true,
        includeUnapproved: true
      }}
      render={({ userTests }) =>
        userTests.length ? (
          <Redirect to={getClientManagementUserTestUrl(userId, userTests[0].id)} />
        ) : (
          <Template selectedTab="tests">
            <NoTests />
          </Template>
        )
      }
    />
  );
}
