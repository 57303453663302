import { useContext } from "react";

import { gql } from "@apollo/client";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import { theme } from "core/theme";
import Danger from "images/Danger";
import Accordion from "tpo/Accordion";
import { SymptomsList } from "tpo/Accordions";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import { CollapseableText } from "tpo/CollapseableText";
import Group from "tpo/Group";
import NothingToShow from "tpo/NothingToShow";
import Spacer from "tpo/Spacer";
import { ViewerContext } from "tpo/Viewer";

import Template from "./shared/Template";

const GET_PARTNER_QUESTIONNAIRE_RESULTS = gql`
  query GET_PARTNER_QUESTIONNAIRE_RESULTS($userId: ID!) {
    userSubmission(userId: $userId) {
      id
      report {
        hasAnswers
        sections {
          presentationSection
          userQuestions {
            id
            question {
              id
              text
            }
            answerScore
            answerText
          }
        }
        outOfRange {
          id
          question {
            id
            text
          }
          answerScore
          answerText
        }
      }
    }
  }
`;

function SelfAssessment({ sections, outOfRange }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: 20
        }}
      >
        <PanelBoxV2
          maxWidth={760}
          outer={{
            px: 20
          }}
        >
          <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Self assessment
          </Box>
          <Box py={[2, 2, 20]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            Find below all responses to the symptoms questionnaire which form part of the wellness
            score. Please encourage your patient for a regular update every 3-6 months to keep it up
            to date.
          </CollapseableText>
          {outOfRange.length && (
            <>
              <Spacer py={[2, 2, 20]} />
              <Center>
                <Group gap={10} alignItems="center">
                  <Danger size={24} />
                  <Box color="error" fontSize={16} fontFamily="gilroyBold">
                    Includes expired answers
                  </Box>
                </Group>
              </Center>
            </>
          )}
        </PanelBoxV2>
        <Box py={[2, 2, 20]} />
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          gap={[10, 10, 20]}
          size={20}
        >
          {outOfRange.length && (
            <Accordion.Item value="outOfRange">
              <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
                <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
                  Summary - Out of range
                </Box>
              </Accordion.Control>
              <Accordion.Panel px={[20, 20, 40]}>
                <SymptomsList questions={outOfRange} />
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {sections?.map(section => (
            <Accordion.Item value={section.presentationSection} key={section.presentationSection}>
              <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
                <Box
                  fontFamily="gilroyBold"
                  fontSize={[16, 16, 18]}
                  lineHeight="130%"
                >{`Section ${section.presentationSection}`}</Box>
              </Accordion.Control>
              <Accordion.Panel px={[20, 20, 40]}>
                <SymptomsList questions={section.userQuestions} />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </PanelBoxV2>
    </>
  );
}

function SelfAssessmentTab() {
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={GET_PARTNER_QUESTIONNAIRE_RESULTS}
      variables={{
        userId: viewerContext.userId
      }}
      render={({ userSubmission }) =>
        userSubmission?.report?.hasAnswers ? (
          <SelfAssessment
            sections={userSubmission.report.sections}
            outOfRange={userSubmission.report.outOfRange}
          />
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  Self assessment
                </Box>
                <Box py={[2, 2, 20]} />
                <CollapseableText fontSize={[14, 14, 16]}>
                  The following answers from the symptom questionnaire are used in th wellness
                  scoring system. Encourage your patient to update this questionnaire every 3-6
                  months.
                </CollapseableText>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  No responses available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

export default function SelfAssessmentRoute() {
  return (
    <Template selectedTab="self_assessment">
      <SelfAssessmentTab />
    </Template>
  );
}
