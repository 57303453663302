import { forwardRef } from "react";

import Box from "components/Box";
import { OUT_OF_STOCK } from "core/constants";
import styled from "styled-components";
import Badge from "tpo/Badge";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2, { ActionIcon } from "v2/Buttons";

const Card = styled(Stack)`
  :hover {
    box-shadow: 2px 2px 4px rgba(26, 26, 26, 0.2);
  }

  cursor: pointer;
`;

const TestProductListCard = forwardRef(
  (
    {
      subtitle,
      title,
      retailFullPrice,
      tradeCurrentPrice,
      tradeFullPrice,
      pills,
      onSelect,
      addText,
      onAdd,
      onRemove,
      hasAddons,
      addDisabled,
      productCode
    },
    ref
  ) => (
    <Card
      bg="white"
      borderRadius="5px"
      py={[24, 24, 40]}
      px={[20, 20, 40]}
      gap={20}
      ref={ref}
      onClick={onSelect}
      data-component-name="TestProductListCard"
      position="relative"
    >
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        justifyContent={["unset", "unset", "space-between"]}
        gap={[20]}
      >
        <Box>
          {productCode && (
            <Box
              position={["static", "static", "absolute"]}
              fontSize={14}
              top={[null, null, 0]}
              transform={[null, null, "translateY(100%)"]}
            >
              {productCode}
            </Box>
          )}
          <Box
            fontFamily="gilroyBold"
            lineHeight="130%"
            fontSize={[24, 24, 28]}
            data-testid="testProductListCard:title"
          >
            {title}
          </Box>
          {hasAddons && (
            <Badge bg="dark" color="white" size="xs" mr="auto" my={"8px"}>
              add-ons available
            </Badge>
          )}
          {subtitle}
        </Box>
        <Box
          display="flex"
          flexDirection={["column", "column", "row"]}
          alignItems={["flex-start"]}
          gap={[10, 10, 40]}
        >
          <Box
            display="flex"
            flexDirection={["column"]}
            gap={5}
            alignItems={["flex-start", "flex-start", "flex-end"]}
          >
            <Group gap={10}>
              {tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice && (
                <Currency
                  fontSize={[24, 44]}
                  value={tradeFullPrice}
                  decimalFontSize={[16, 28]}
                  color="dark"
                  strikethrough
                  data-testid="testProductListCard:tradeFullPrice"
                />
              )}
              <Currency
                fontSize={[24, 44]}
                value={tradeCurrentPrice}
                decimalFontSize={[16, 28]}
                data-testid="testProductListCard:tradeCurrentPrice"
                color={
                  tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice
                    ? "red"
                    : "dark"
                }
              />
            </Group>
            <Currency
              prefix="RRP"
              color="midGrey"
              fontSize={18}
              value={retailFullPrice}
              data-testid="testProductListCard:retailFullPrice"
            />
          </Box>
          {onAdd && (
            <ButtonV2
              onClick={onAdd}
              disabled={addDisabled}
              color={addDisabled ? "midGrey" : "green"}
              rightIcon={<ChevronComponent />}
              size={["sm", "sm", "md"]}
            >
              {addDisabled === OUT_OF_STOCK ? "Out of stock" : addText}
            </ButtonV2>
          )}
          {onRemove && (
            <ButtonV2
              onClick={onRemove}
              color="red"
              rightIcon={<ChevronComponent />}
              size={["sm", "sm", "md"]}
            >
              Remove
            </ButtonV2>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection={["column", "column", "row"]} gap={20}>
        <Group flexWrap="wrap" gap={2} mt="auto">
          {pills}
        </Group>
        <ActionIcon bg="haze" variant="circle" ml={["auto"]} onClick={onSelect}>
          <ChevronComponent
            fill="dark"
            style={{
              margin: 0
            }}
          />
        </ActionIcon>
      </Box>
    </Card>
  )
);

export default TestProductListCard;
