import { Redirect, useParams } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import DataLoader from "components/DataLoader";
import { useBasketContext } from "contexts/BasketContext";
import { ChooseClinicProvider } from "contexts/ChooseClinicProvider";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { TestProductOptionProvider } from "contexts/TestProductOptionContext";
import { ERROR_404_URL } from "core/urls";
import {
  CONSUMER_TEST_PRODUCT_DETAIL_PAGE_QUERY,
  CONSUMER_TEST_PRODUCT_OPTION_FIELDS,
  GET_CONSUMER_CLINICS
} from "graphql/shop";
import { createGlobalStyle } from "styled-components";
import Modal from "tpo/Modal";

import { ChooseClinic } from "./BaseTestProductModal";
import Full from "./testProductDetail/page/Full";
import Truncated from "./testProductDetail/page/Truncated";
import showFullDetails from "./testProductDetail/utils/showFullDetails ";

const GlobalStyle = createGlobalStyle`
  .PoweredByRegenerusBanner svg {
    fill: black;
  }
`;

export const TRUNCATED_TEST_PRODUCT_VIEW_BG = "haze";
export const FULL_TEST_PRODUCT_VIEW_BG = "haze";

export function TestProductDetail({ testProduct, userTestRecommendation }) {
  const {
    selectedTestProductOptionId,
    selectTestProductOptionId,
    testProductOption
  } = useTestProductModalContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  let content = null;

  const fullView = showFullDetails(testProduct);

  if (fullView) {
    content = <Full testProduct={testProduct} userTestRecommendation={userTestRecommendation} />;
  } else {
    content = (
      <Truncated testProduct={testProduct} userTestRecommendation={userTestRecommendation} />
    );
  }

  const show =
    selectedTestProductOptionId &&
    testProductOption &&
    testProductOption.consumerClinicsInfo?.clinics?.length;

  return (
    <>
      {content}
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [20, 20, 40]
        }}
        bg="white"
        show={show}
        close={() => selectTestProductOptionId(undefined)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <ChooseClinic
          onAddToBasket={({ clinic }) => {
            addItemToBasket({
              compositeId: testProductOption.compositeId,
              clinicLocationId: clinic.id
            });
            setBasketOpen(true);
            selectTestProductOptionId(undefined);
          }}
          query={GET_CONSUMER_CLINICS}
          testProductOptionPriceField="consumerCurrentPrice"
          clinicLocationsField="consumerClinicLocations"
        />
      </Modal>
    </>
  );
}

export default function TestProductDetailPage() {
  const { slug } = useParams();

  return (
    <DataLoader
      query={CONSUMER_TEST_PRODUCT_DETAIL_PAGE_QUERY}
      variables={{
        currency: "GBP",
        slug
      }}
      render={({ consumerTestProduct: testProduct, userTestRecommendation }) =>
        testProduct ? (
          <CustomerPage>
            <GlobalStyle />
            <TestProductOptionProvider
              testProductOptionFragment={CONSUMER_TEST_PRODUCT_OPTION_FIELDS}
              testProductOptionFragmentName="ConsumerTestProductOptionFields"
              testProductOptionFragmentType="TestProductOptionType"
            >
              <ChooseClinicProvider>
                <TestProductDetail
                  testProduct={testProduct}
                  userTestRecommendation={userTestRecommendation}
                />
              </ChooseClinicProvider>
            </TestProductOptionProvider>
          </CustomerPage>
        ) : (
          <Redirect to={ERROR_404_URL} />
        )
      }
    />
  );
}
