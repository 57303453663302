import { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import { ExternalTextLink, InternalTextLink } from "components/Links";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { getAPIBase } from "core/config";
import { theme } from "core/theme";
import { ORGANISATION_STOCK_LIST_QUERY } from "graphql/organisations/queries/stock";
import { useDataLoader } from "hooks/useDataLoader";
import useListControls from "hooks/useListControls";
import useRefetch from "hooks/useRefetch";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import { List, ListContext } from "tpo/List";
import Modal from "tpo/Modal";
import SimpleTable from "tpo/SimpleTable";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import Table from "tpo/Table";
import ButtonV2 from "v2/Buttons";

import OrganisationTabs from "../OrganisationTabs";
import { REGENERUS_REGENCY } from "../constants";
import OrderIcon from "./ui/OrderIcon";
import RegisterStock from "./ui/RegisterStock";
import Switcher from "./ui/Switcher";

function StockDetails({ children, product, order, omnosBarcodeId, orderTestItem }) {
  return (
    <Stack gap={5}>
      <Box fontFamily="gilroyBold" fontSize={16} data-testid="productName">
        {product.name}
      </Box>
      <InternalTextLink href={`/partners/dashboard/order/${order.id}`}>
        Order No: {order.id}
      </InternalTextLink>
      {product.providerApi !== REGENERUS_REGENCY ? (
        <Box fontFamily="gilroyMedium" fontSize={14} data-testid="KitRegistrationID">
          Kit registration ID: {orderTestItem?.providerTestId || omnosBarcodeId || "Awaiting code"}
        </Box>
      ) : (
        (orderTestItem?.providerTestId || omnosBarcodeId) && (
          <Box fontFamily="gilroyMedium" fontSize={14} data-testid="KitRegistrationID">
            Kit registration ID: {orderTestItem?.providerTestId || omnosBarcodeId}
          </Box>
        )
      )}
      {children}
    </Stack>
  );
}

export function OrganisationStock({ statusFilters = [] }) {
  const history = useHistory();

  const [filterTab, setFilterTab] = useState("all");

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedStockId, setSelectedStockId] = useState();
  const [page, setPage] = useState(1);

  // use the url state to determine if we should preselect a stock id
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stockId = urlParams.get("stock");
    if (stockId) {
      setSelectedStockId(stockId);
    }
  }, []);

  const controls = useListControls({
    defaultSort: "-created_from_order__checkout_date",
    sortOptions: [
      {
        label: "A - Z",
        value: "test_product__name"
      },
      {
        label: "Z - A",
        value: "-test_product__name"
      },
      {
        label: "Oldest To Newest",
        value: "created_from_order__checkout_date"
      },
      {
        label: "Newest To Oldest",
        value: "-created_from_order__checkout_date"
      }
    ]
  });

  const { organisation } = useOrganisationContext();
  const { data, loading, refetch: refetchStock } = useDataLoader(
    {
      query: ORGANISATION_STOCK_LIST_QUERY,
      variables: {
        page,
        organisation: parseInt(organisation?.id),
        orderBy: controls.sort,
        search: controls.debouncedSearch,
        filter: filterTab
      },
      cachePolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !organisation
    },
    [organisation, page, controls.sort, controls.debouncedSearch, filterTab]
  );

  useRefetch(ORGANISATION_STOCK_LIST_QUERY, refetchStock);

  const orders = data?.organisationStockPagination?.items;
  const pageInfo = data?.organisationStockPagination?.pageInfo;

  const columns = useMemo(
    () => [
      {
        id: "kit",
        label: "Kit",
        accessor: ({
          id,
          omnosBarcodeId,
          testProduct: product,
          createdFromOrder: order,
          orderTestItem
        }) => {
          return (
            <StockDetails
              omnosBarcodeId={omnosBarcodeId}
              product={product}
              order={order}
              orderTestItem={orderTestItem}
            />
          );
        }
      },
      {
        id: "orderDate",
        accessor: stock =>
          new Date(stock.createdFromOrder.checkoutDate).toLocaleDateString("en-GB"),
        label: "Ordered"
      },
      {
        id: "expires",
        accessor: stock => {
          // TODO Replace with real expiry calculations
          const date = new Date(stock.createdFromOrder.checkoutDate);
          date.setMonth(date.getMonth() + 3);
          return date.toLocaleDateString("en-GB");
        },
        label: "Expires"
      },
      {
        id: "lastColumn",
        accessor: ({ id, registeredUser, orderTestItem, testProduct, omnosBarcodeId }) => {
          if (registeredUser) {
            return (
              <Stack gap={5}>
                <Badge bg="green" color="white" ml="auto" py="8px" px="16px" fontSize={10}>
                  Registered
                </Badge>
                <Box fontFamily="gilroyBold" fontSize={16}>
                  {registeredUser.firstName} {registeredUser.lastName}
                </Box>
                {testProduct.providerApi === REGENERUS_REGENCY && (
                  <ExternalTextLink
                    href={`${getAPIBase()}/requisition/download/${orderTestItem.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonV2
                      color="green"
                      rightIcon={<ChevronComponent />}
                      ml="auto"
                      mt={2}
                      size={["sm", "sm", "md"]}
                    >
                      Lab form
                    </ButtonV2>
                  </ExternalTextLink>
                )}
              </Stack>
            );
          } else {
            return (
              <ButtonV2
                color="dark"
                rightIcon={<ChevronComponent />}
                onClick={() => setSelectedStockId(id)}
                disabled={
                  testProduct.providerApi !== REGENERUS_REGENCY &&
                  !(orderTestItem?.providerTestId || omnosBarcodeId)
                }
                size={["sm", "sm", "md"]}
              >
                Register
              </ButtonV2>
            );
          }
        },
        align: "right"
      }
    ],
    []
  );

  const statusTabs = [
    {
      label: "all",
      slug: "all"
    },
    {
      label: "registered",
      slug: "registered"
    },
    {
      label: "not registered",
      slug: "not_registered"
    }
  ];

  return (
    <>
      <OrganisationTabs selectedTab="orders" />
      <Stack
        maxWidth={1538}
        mx="auto"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, "5.5vw"]}
        gap={[20, 20, 40]}
      >
        <Center>
          <Switcher />
        </Center>
        <Box
          bg="white"
          borderRadius={5}
          maxWidth={1280}
          pt={theme.spacing.section.pt}
          pb={theme.spacing.section.pb}
          px={[20, 20, 40]}
        >
          <Box maxWidth={1020} mx="auto">
            <Group gap={20}>
              <OrderIcon width={[30, 30, 40]} />
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Organisation Stock
              </Box>
            </Group>
            <Spacer py={[2, 2, 20]} />
            <SimpleTable
              controls={
                <SortMenu
                  value={filterTab || null}
                  setValue={v => setFilterTab(v)}
                  open={showFilterMenu}
                  setOpen={setShowFilterMenu}
                  options={statusTabs.map(({ label, slug }) => ({
                    label: label.toUpperCase(),
                    value: slug
                  }))}
                  styles={{
                    button: {
                      color: "dark",
                      marginRight: "auto",
                      sx: {
                        color: "white"
                      }
                    }
                  }}
                  placeholder="Filter by status"
                />
              }
              search={controls.search}
              setSearch={controls.setSearch}
              sort={controls.sort}
              setSort={controls.setSort}
              sortMenuOpen={controls.sortMenuOpen}
              setSortMenuOpen={controls.setSortMenuOpen}
              pageInfo={pageInfo}
              setPage={setPage}
              sortingOptions={controls.sortOptions}
              table={
                <>
                  <Box display={["none", "none", "block"]}>
                    <Table columns={columns} loading={loading} rows={orders} />
                  </Box>
                  <Box display={["block", "block", "none"]}>
                    <List items={orders} loading={loading}>
                      <ListContext.Consumer>
                        {items =>
                          items?.map(item => {
                            return (
                              <Stack
                                key={item.id}
                                gap={20}
                                py={20}
                                style={{
                                  borderBottom: `1px solid ${theme.colors.midGrey}`
                                }}
                              >
                                <StockDetails
                                  omnosBarcodeId={item.omnosBarcodeId}
                                  product={item.testProduct}
                                  order={item.createdFromOrder}
                                  orderTestItem={item.orderTestItem}
                                />
                                <Group gap={10}>
                                  <Box fontFamily="gilroyBold" fontSize={16}>
                                    Order date:
                                  </Box>
                                  {columns[1].accessor(item)}
                                </Group>
                                <Group gap={10}>
                                  <Box fontFamily="gilroyBold" fontSize={16}>
                                    Expiry date:
                                  </Box>
                                  {columns[2].accessor(item)}
                                </Group>
                                <Group justifyContent="flex-start">
                                  {columns[3].accessor(item)}
                                </Group>
                              </Stack>
                            );
                          })
                        }
                      </ListContext.Consumer>
                    </List>
                  </Box>
                </>
              }
            />
          </Box>
        </Box>
      </Stack>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [20, 20, 40]
        }}
        bg="white"
        show={!!selectedStockId}
        close={() => {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.delete("stock");
          history.push({ search: urlParams.toString() });
          setSelectedStockId();
        }}
        mode={["fullScreen", "fullScreen", "centered"]}
        data-component-name="RegisterStock"
      >
        <PanelBoxV2
          maxWidth={760}
          outer={{
            bg: "white",
            px: [20, 20, 40],
            pb: theme.spacing.section.pb,
            borderRadius: 5
          }}
        >
          <RegisterStock stockId={selectedStockId} />
        </PanelBoxV2>
      </Modal>
    </>
  );
}
