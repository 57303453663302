import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";

import PatientNotificationSettingsForm from "./forms/PatientNotificationSettingsForm";
import BusinessTemplate from "./ui/BusinessTemplate";

export default function Settings() {
  return (
    <BusinessTemplate active="settings">
      <PartnerDashboardBox>
        <PatientNotificationSettingsForm />
      </PartnerDashboardBox>
    </BusinessTemplate>
  );
}
