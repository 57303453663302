import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { theme } from "core/theme";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import OrganisationTabs from "../OrganisationTabs";
import classes from "./Support.module.css";

const links = [
  {
    label: "Cardiovascular",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/25345885/calendar/any?appointmentTypeIds%5B%5D=25345885"
  },
  {
    label: "Cyrex",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/16492239/calendar/any?appointmentTypeIds%5B%5D=16492239"
  },
  {
    label: "DUTCH",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/5436212/calendar/any?appointmentTypeIds%5B%5D=5436212"
  },
  {
    label: "OAT",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/25238453/calendar/any?appointmentTypeIds%5B%5D=25238453"
  },
  {
    label: "SIBO",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/25238424/calendar/any?appointmentTypeIds%5B%5D=25238424"
  },
  {
    label: "Stool",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/19160324/calendar/any?appointmentTypeIds%5B%5D=19160324"
  },
  {
    label: "Thyroid",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/25238434/calendar/any?appointmentTypeIds%5B%5D=25238434"
  },
  {
    label: "Viruses, Co infections and Lyme",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/6796305/calendar/any?appointmentTypeIds%5B%5D=6796305"
  },
  {
    label: "EnviroTox",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/38486525/calendar/any?appointmentTypeIds%5B%5D=38486525"
  },
  {
    label: "MycoTox",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/38486585/calendar/any?appointmentTypeIds%5B%5D=38486585"
  },
  {
    label: "Blood Profile",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/38486620/calendar/any?appointmentTypeIds%5B%5D=38486620"
  },
  {
    label: "Microgen Dx",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/38486638/calendar/any?appointmentTypeIds%5B%5D=38486638"
  },
  {
    label: "HuMap",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/38486558/calendar/any?appointmentTypeIds%5B%5D=38486558"
  },
  {
    label: "DNA",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/51909473/calendar/any?appointmentTypeIds%5B%5D=51909473"
  },
  {
    label: "Blood Profile (Omnos only)",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/55302692/calendar/any?appointmentTypeIds%5B%5D=55302692"
  },
  {
    label: "TruAge",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/62956334/calendar/any?appointmentTypeIds%5B%5D=62956334"
  },
  {
    label: "Other",
    url:
      "https://app.acuityscheduling.com/schedule/a4df7d3b/appointment/29701754/calendar/any?appointmentTypeIds%5B%5D=29701754"
  }
];

export default function Support() {
  return (
    <>
      <OrganisationTabs selectedTab="support" />
      <PanelBoxV2
        maxWidth={1532}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20],
          bg: "haze"
        }}
        stacked
        gap={[24, 24, 40]}
      >
        <PanelBoxV2 maxWidth={1020}>
          <PanelBoxV2
            maxWidth={760}
            outer={{
              pt: [30, 30, 60],
              pb: [50, 50, 80],
              bg: "white",
              borderRadius: 5,
              px: 20
            }}
            stacked
            gap={[24, 24, 40]}
          >
            <Stack gap={[20, 20, 28]}>
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Book a Support call
              </Box>
              <Stack
                as="ul"
                fontFamily="gilroyMedium"
                fontSize={[14, 14, 16]}
                listStyle="disc"
                style={{
                  listStylePosition: "inside"
                }}
              >
                What can I expect from a support call?
                <Box as="li" fontFamily="inherit" fontSize="inherit" marginLeft={1}>
                  A 15-minute support call includes either test interpretation or test selection for
                  a client. 
                </Box>
                <Box as="li" fontFamily="inherit" fontSize="inherit" marginLeft={1}>
                  During your call, we will walk you through your patient results in conjunction
                  with symptomology and provide general guidance. We are not able to give specific
                  protocols or supplement recommendations.
                </Box>
                <Box as="li" fontFamily="inherit" fontSize="inherit" marginLeft={1}>
                  When booking your appointment all required fields need to be completed. Failure to
                  do so may result in your call being canceled.
                </Box>
              </Stack>
            </Stack>
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
              Book a call for support with the following areas
            </Box>
            <Stack className={classes.supportLinkStack}>
              {links.map(({ label, url }) => (
                <Group
                  justifyContent="space-between"
                  key={label}
                  alignItems="center"
                  px={[0, 0, "8px"]}
                  py={"8px"}
                  className={classes.supportStackItem}
                >
                  <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                    {label}
                  </Box>
                  <ButtonV2
                    as={ExternalTextLink}
                    href={url}
                    color="dark"
                    rightIcon={<ChevronComponent />}
                    size={["xs", "xs", "sm"]}
                    underline={false}
                  >
                    book now
                  </ButtonV2>
                </Group>
              ))}
            </Stack>
          </PanelBoxV2>
        </PanelBoxV2>
        <PanelBoxV2 maxWidth={1020}>
          <PanelBoxV2
            maxWidth={760}
            outer={{
              pt: [30, 30, 60],
              pb: [50, 50, 80],
              bg: "white",
              borderRadius: 5,
              px: 20
            }}
            stacked
            gap={[20, 20, 28]}
          >
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
              General support
            </Box>
            <Box fontSize={[14, 14, 16]}>
              <Box as="span" fontSize="inherit">
                Clinical Support Team
              </Box>{" "}
              at{" "}
              <ExternalTextLink
                fontSize="inherit"
                href="mailto:support@regeneruslabs.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@regeneruslabs.com
              </ExternalTextLink>
            </Box>
            <Box fontSize={[14, 14, 16]}>
              <Box fontSize="inherit">
                <Box as="span" fontSize="inherit">
                  Client Service Team
                </Box>{" "}
                at{" "}
                <ExternalTextLink
                  fontSize="inherit"
                  href="mailto:info@regeneruslabs.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@regeneruslabs.com
                </ExternalTextLink>
              </Box>
              <Box fontSize="inherit">or by phone, Monday-Friday, from 9:30am - 4:30pm.</Box>
              <Box fontSize="inherit">Phone lines are closed between 1:15 pm and 2:15 pm.</Box>
              <Box fontSize="inherit" textDecoration="underline">
                Tel: 020 3750 0870.
              </Box>
            </Box>
          </PanelBoxV2>
        </PanelBoxV2>
      </PanelBoxV2>
    </>
  );
}
