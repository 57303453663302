import { useEffect, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import { useAuthContext } from "components/AuthProvider";
import Box from "components/Box";
import { useHeaderContext } from "contexts/HeaderContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { TESTS_URL, getPartnerShopTabUrl } from "core/urls";
import useWindowSize from "hooks/use-window-size";
import RegenerusLogoInline from "images/RegenerusLogoInline";
import RegenerusLogoMobile from "images/RegenerusLogoMobile";
import Center from "tpo/Center";
import Group from "tpo/Group";
import LinkWrapper from "tpo/LinkWrapper";
import BurgerButton from "tpo/header/BurgerButton";

import { SHOP_TESTS_URL } from "../../core/urls";
import AffiliateDrawer from "./AffiliateDrawer";
import BackButton from "./BackButton";
import BasketButtonUi from "./BasketButtonUi";
import Container from "./Container";
import LoggedInMenuItems from "./LoggedInMenuItems";
import LoggedOutMenuDrawer from "./LoggedOutMenuDrawer";
import LoggedOutMenuItems from "./LoggedOutMenuItems";
import PractitionerDrawer from "./PractitionerDrawer";
import ReleaseButton from "./ReleaseButton";

export default function PartnerHeader({ user }) {
  // PartnerHeader should only be rendered if the user is a partner
  // and the user is at a partner related url

  const location = useLocation();
  const { open, onClick } = useHeaderContext();

  const {
    practitionerBasketOpen: basketOpen,
    setPractitionerBasketOpen: setBasketOpen
  } = useAppState();

  const [basketCount, setBasketCount] = useState(0);

  const { basket } = useOrganisationBasketContext();

  useEffect(() => {
    if (basket) {
      let count = basket.productLineItems.reduce((total, item) => total + item.quantity, 0);
      let supplementCount = basket.supplementLineItems.reduce(
        (total, item) => total + item.quantity,
        0
      );
      setBasketCount(count + supplementCount);
    }
  }, [basket]);

  const isPractitioner = user?.partnerUserProfile?.tier === "Practitioner";
  const isAffiliate = user?.partnerUserProfile?.tier === "Affiliate";

  let drawerMenu = <LoggedOutMenuDrawer bg="dark" />;
  if (isPractitioner) {
    drawerMenu = <PractitionerDrawer bg="dark" user={user} />;
  } else if (isAffiliate) {
    drawerMenu = <AffiliateDrawer bg="dark" user={user} />;
  }

  let common = (
    <>
      <BurgerButton onClick={open} />
      {drawerMenu}
    </>
  );

  let center = user ? (
    <>
      <Box
        as={LinkWrapper}
        color="white"
        fontFamily="gilroyBold"
        fontSize={16}
        to={isPractitioner ? getPartnerShopTabUrl("tests") : SHOP_TESTS_URL}
        onClick={onClick}
        display={["none", "none", "inline"]}
      >
        Shop
      </Box>
      <LoggedInMenuItems bg="dark" user={user} />
      {common}
    </>
  ) : (
    <>
      <Box
        as={LinkWrapper}
        color="white"
        fontFamily="gilroyBold"
        fontSize={16}
        to={TESTS_URL}
        onClick={onClick}
        display={["none", "none", "inline"]}
      >
        Shop
      </Box>
      <LoggedOutMenuItems bg="dark" />
      {common}
    </>
  );

  let right = (
    <BasketButtonUi
      basketCount={basketCount}
      setBasketOpen={setBasketOpen}
      basketOpen={basketOpen}
    />
  );

  if (location.pathname.startsWith("/patients")) {
    center = null;
    right = null;
  }

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 960;

  const history = useHistory();

  const authContext = useAuthContext();
  const impersonated = authContext?.user?.impersonated;

  return (
    <Container bg="transparent" data-component-name="NavHeader" height={[45, 45, 60]}>
      <Group justifyContent="space-between" gap={10} height="100%">
        <Center bg="dark" p={20} borderRadius="10px" height="100%" width={[45, 45, 60]}>
          <BackButton onClick={() => history.goBack()} />
        </Center>
        <Group
          alignItems="center"
          bg="dark"
          justifyContent="space-between"
          flexGrow={1}
          p={2}
          borderRadius="10px"
          px={20}
          height="100%"
        >
          <Group alignItems="center" justifyContent="space-between" mr="auto">
            <RegenerusLogoInline
              fill="white"
              style={{
                display: isMobile ? "none" : "block"
              }}
            />
            <RegenerusLogoMobile
              fill="white"
              style={{
                display: isMobile ? "block" : "none"
              }}
            />
            {impersonated && <ReleaseButton ml="10px" user={user} />}
          </Group>
          {center && (
            <Group alignItems="center" justifyContent="space-between" gap={20}>
              {center}
            </Group>
          )}
        </Group>
        {right && (
          <Group
            alignItems="center"
            bg="dark"
            p={20}
            borderRadius="10px"
            height="100%"
            width={[45, 45, 60]}
            justifyContent="center"
          >
            {right}
          </Group>
        )}
      </Group>
    </Container>
  );
}
