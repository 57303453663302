import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION } from "graphql/organisations/mutations";
import Checkbox from "tpo/Checkbox";
import Stack from "tpo/Stack";

export default function StripeConnectDetailsPanel() {
  const { organisation } = useOrganisationContext();

  const [updateAutomaticInvoicingSettingMutation] = useMutation(
    UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION
  );

  const updateAutomaticInvoicingSetting = useCallback(
    async variables => {
      if (!organisation?.id) {
        return;
      }
      try {
        await updateAutomaticInvoicingSettingMutation({
          variables: {
            input: {
              id: parseInt(organisation.id),
              ...variables
            }
          }
        });
      } catch (e) {}
    },
    [updateAutomaticInvoicingSettingMutation, organisation]
  );

  const handleToggleAutomaticInvoicing = useCallback(
    checked => {
      updateAutomaticInvoicingSetting({
        automaticInvoicingEnabled: checked
      });
    },
    [updateAutomaticInvoicingSetting]
  );

  return (
    <>
      <Stack gap={20}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Automatic invoicing
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          For a more seamless payment integration you can opt in to have invoices automatically
          taken from your account when they are due. If you opt in we will give you an extra 5%
          discount on your next order.
        </Box>
      </Stack>
      <Checkbox
        label={"Allow payment to be automatically taken after 45 days"}
        checked={organisation?.automaticInvoicingEnabled}
        onChange={({ target }) => handleToggleAutomaticInvoicing(target.checked)}
      />
    </>
  );
}
