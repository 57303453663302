import { useState } from "react";

import Box from "components/Box";
import Grid from "components/Grid";
import BaseProductQtyInput from "components/ProductQtyInput";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import Badge from "tpo/Badge";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import classes from "./BasketItem.module.css";

function ProductQtyInput({ count: initialCount, disabled, setQty }) {
  const [count, setCount] = useState(initialCount);

  return (
    <BaseProductQtyInput
      value={count}
      disabled={disabled}
      onChange={n => {
        setCount(n);
      }}
      onBlur={() => {
        if (count !== "") {
          if (count !== initialCount) {
            setQty(count);
          }
        } else {
          setCount(initialCount);
        }
      }}
    />
  );
}

function BasketItemUI({
  count: initialCount,
  name,
  subname,
  price,
  previousPrice,
  rrpPrice,
  setQty,
  removeItemFromBasket,
  addons,
  onClick,
  "data-component-name": dataComponentName = "BasketItemUI",
  className,
  py
}) {
  const { removeAddOnFromProduct, loading } = useOrganisationBasketContext();

  return (
    <Grid
      className={className}
      gridTemplateColumns="auto 1fr"
      data-component-name={dataComponentName}
      gridRowGap={["10px", "10px", "20px"]}
      style={{
        gridColumnGap: 10
      }}
      py={py}
    >
      <Group gap={10}>
        {setQty ? (
          <ProductQtyInput
            key={initialCount}
            count={initialCount}
            disabled={loading}
            setQty={setQty}
          />
        ) : null}
        <Group
          alignItems="flex-start"
          justifyContent="space-between"
          flexGrow={1}
          flexDirection="column"
          m="auto"
        >
          <Box
            className={classes.BasketItem}
            onClick={onClick}
            fontFamily="gilroyBold"
            fontSize={[16, 16, 18]}
            data-testid="basket-item-name"
          >
            {name}
          </Box>
          {subname && (
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              color="grey"
              data-testid="basket-item-subname"
            >
              {subname}
            </Box>
          )}
          <ButtonV2
            mt={1}
            letterSpacing="unset"
            fontSize={16}
            textTransform="none"
            variant="link"
            onClick={
              setQty
                ? () => {
                    setQty(0);
                  }
                : removeItemFromBasket
            }
            disabled={loading}
            fontFamily="gilroyRegular"
            data-component-name="RemoveButton"
            size={null}
          >
            Remove
          </ButtonV2>
        </Group>
      </Group>
      <Group
        alignItems="flex-start"
        gap={10}
        justifyContent="center"
        flexGrow={1}
        gridRowStart={["2", "1"]}
        gridRowEnd={["3", "1"]}
        gridColumnStart={["1", "2"]}
        gridColumnEnd={["3"]}
      >
        <Group
          alignItems="flex-end"
          justifyContent="space-between"
          flexGrow={1}
          flexDirection="column"
          style={{ minWidth: "80px" }}
        >
          <Group alignItems="flex-start" gap={4} justifyContent="space-between" flexGrow={1} my={1}>
            {previousPrice && previousPrice > price && (
              <Currency
                fontSize={28}
                value={previousPrice}
                color="black"
                strikethrough
                data-testid="basket-item-previous-price"
              />
            )}
            <Currency
              fontSize={28}
              value={price}
              color={previousPrice && previousPrice > price ? "red" : "black"}
              data-testid="basket-item-price"
            />
          </Group>
          {rrpPrice && (
            <Currency
              fontSize={18}
              value={rrpPrice}
              prefix="RRP"
              color="midGrey"
              strikethrough
              data-testid="basket-item-rrpprice"
            />
          )}
        </Group>
      </Group>
      {!!addons?.length && (
        <Stack
          gap={10}
          gridColumnStart={["1"]}
          gridColumnEnd={["3"]}
          data-component-name="BasketItemUiAddons"
          mt={2}
        >
          {addons.map(addon => (
            <Group
              key={addon.key}
              gap={10}
              justifyContent="space-between"
              alignItems="center"
              data-component-name="BasketItemUiAddon"
            >
              <Group alignItems="center" gap={10} flexWrap="wrap">
                <Badge bg="dark" color="white" size="md" fontSize={10}>
                  add-on
                </Badge>
                <ButtonV2
                  color="red"
                  size="xs"
                  type="button"
                  onClick={() => {
                    removeAddOnFromProduct(addon.parentProduct.id, addon.product.id);
                  }}
                >
                  remove
                </ButtonV2>
                <Box fontFamily="gilroyBold" fontSize={[16]} data-testid="BasketItemUiAddon:name">
                  {addon.name}
                </Box>
              </Group>
              <Currency
                value={addon.price}
                fontSize={18}
                symbol="£"
                data-testid="BasketItemUiAddon:price"
              />
            </Group>
          ))}
        </Stack>
      )}
    </Grid>
  );
}

export function BasketItem({
  count,
  name,
  subname,
  price,
  previousPrice,
  rrpPrice,
  clinicName,
  address,
  fees,
  setQty,
  removeItemFromBasket,
  addons,
  onClick
}) {
  if (clinicName) {
    return (
      <Stack className="BasketItemUI" gap={20} data-component-name="BasketItemUI" py={20}>
        <BasketItemUI
          count={count}
          name={name}
          subname={subname}
          price={price}
          previousPrice={previousPrice}
          setQty={setQty}
          removeItemFromBasket={removeItemFromBasket}
          addons={addons}
          onClick={onClick}
          data-component-name={null}
        />
        <Box>
          <Box fontFamily="gilroyBold" fontSize={16}>
            {clinicName}
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={14}>
            {address}
          </Box>
          <Group alignItems="center" justifyContent="space-between">
            <Box fontFamily="gilroyBold" fontSize={16}>
              Clinic fee
            </Box>
            <Currency fontSize={18} value={fees} data-testid="clinicFee" />
          </Group>
        </Box>
      </Stack>
    );
  } else {
    return (
      <BasketItemUI
        count={count}
        name={name}
        subname={subname}
        price={price}
        rrpPrice={rrpPrice}
        previousPrice={previousPrice}
        setQty={setQty}
        removeItemFromBasket={removeItemFromBasket}
        addons={addons}
        onClick={onClick}
        py={20}
        className="BasketItemUI"
      />
    );
  }
}
