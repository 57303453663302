import { useEffect, useRef, useState } from "react";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import Page from "components/Page";
import { FadeTransition } from "components/animations/FadeTransition";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { theme } from "core/theme";
import { SHIPPING_COUNTRIES_QUERY } from "graphql/shop";
import { useOnScreen } from "hooks/useOnScreen";
import { PanelBoxV2 } from "tpo/Boxes";

import OrganisationTabs from "../OrganisationTabs";
import { OrganisationBasketListing } from "./OrganisationBasketListing";
import { BasketTotals } from "./ui/BasketTotals";
import FinaliseBasketPanel from "./ui/FinaliseBasketPanel";
import PatientSelection from "./ui/PatientSelection";
import ShippingDetails from "./ui/ShippingDetails";

export default function Checkout() {
  const basketSummaryRef = useRef();

  const basketSummaryIsVisible = useOnScreen(basketSummaryRef);

  const { data: shippingData, error, loading } = useQuery(SHIPPING_COUNTRIES_QUERY);
  const [shippingCountries, setShippingCountries] = useState([]);

  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  useEffect(() => {
    if (shippingData) {
      // Sort shipping countries by name, except for the UK which should be first
      let countries = [...shippingData.shippingCountries].sort((a, b) => {
        if (a.name === "United Kingdom") {
          return -1;
        }
        if (b.name === "United Kingdom") {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
      setShippingCountries(countries);
    }
  }, [shippingData]);

  useEffect(() => {
    // if the basket is empty, redirect to the shop
    if (basket && basket.productLineItems.length === 0 && basket.supplementLineItems.length === 0) {
      window.location.href = "/partners/dashboard/shop";
    }
  }, [basket]);

  return (
    <>
      <OrganisationTabs selectedTab="shop" />
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [60, 60, 80],
          pb: 60,
          px: [20, 20, "5.5vw"],
          bg: "haze"
        }}
        stacked
        gap={40}
      >
        <Box fontFamily="gilroyBold" fontSize={36}>
          Checkout
        </Box>
        <PanelBoxV2
          ref={basketSummaryRef}
          maxWidth={760}
          outer={{
            bg: "white",
            px: [20, 20, 40],
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            borderRadius: 5
          }}
        >
          <OrganisationBasketListing />
        </PanelBoxV2>
        <FadeTransition in={!basket?.purchaseAsStock}>
          <PatientSelection />
        </FadeTransition>
        <ShippingDetails shippingCountries={shippingCountries} />
        <FinaliseBasketPanel
          canSubmit={
            basket?.shippingAddress &&
            (basket?.patient || basket?.purchaseAsStock) &&
            (basket?.productLineItems.length > 0 || basket?.supplementLineItems.length > 0)
          }
        />
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "white",
          position: "sticky",
          bottom: 0,
          borderTopColor: "haze",
          borderTopStyle: "solid",
          borderTopWidth: 1,
          px: 40,
          pt: 20,
          pb: 40,
          transition: "all 100ms ease-out",
          transform: basketSummaryIsVisible ? "translateY(100%)" : "translateY(0)",
          opacity: basketSummaryIsVisible ? 0 : 1
        }}
        stacked
        gap={0}
        inner={{
          alignItems: "flex-end"
        }}
      >
        <BasketTotals basket={basket} payee={basket?.payee} />
      </PanelBoxV2>
    </>
  );
}
