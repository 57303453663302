import React, { useEffect, useState } from "react";

import { gql } from "@apollo/client";
import PropTypes from "prop-types";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import Text, { SpanText } from "../components/Text";
import {
  statusFromOrderTestItem,
  RESULTS_AVAILABLE,
  CONSULT_NEEDED,
  statusFromUserTest
} from "../core/utils";
import { ORDER_TEST_ITEM_FIELDS } from "../graphql/shop";
import { USER_TEST_FIELDS_TRUNCATED } from "../graphql/tpo/results/types";
import { useDidUpdate } from "../hooks/useDidUpdate";
import { ReactComponent as TestIcon } from "../images/test-order.svg";
import { SelectDropdownStatusIcon, SelectExpand } from "./Expand";

export const USER_TESTS_AND_ORDERS_QUERY = gql`
  query UserTestsAndOrdersQuery($allTests: Boolean, $excludeRetests: Boolean) {
    userTests(allTests: $allTests, excludeRetests: $excludeRetests) {
      ...UserTestFieldsTruncated
    }
    ordersInProgress {
      ...OrderTestItemFields
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
`;

export const CLIENT_TESTS = gql`
  query ClientTestsQuery($userId: ID) {
    userTests(userId: $userId) {
      ...UserTestFieldsTruncated
    }
  }
  ${USER_TEST_FIELDS_TRUNCATED}
`;

function combineOrdersAndResults({ testResults, ordersInProgress }) {
  let combinedStatuses = [];
  let seenTestIds = new Set();

  testResults.forEach(userTest => {
    seenTestIds.add(userTest.id);
    combinedStatuses.push({
      name: userTest.name,
      slug: `${userTest.slug}/${userTest.id}`,
      id: userTest.id,
      created: userTest.createdDateFormatted,
      status: statusFromUserTest(userTest)
    });
  });

  ordersInProgress.forEach(order => {
    // if this order has any tests that are in the test results, hide it
    if (order.userTestSet.some(userTest => seenTestIds.has(userTest.id))) {
      return;
    }
    combinedStatuses.push({
      name: order.product.name,
      id: order.id,
      status: statusFromOrderTestItem(order)
    });
  });

  return combinedStatuses;
}

export function useTestStatuses({ testResults, ordersInProgress }) {
  const [statuses, setStatuses] = useState(
    combineOrdersAndResults({
      testResults,
      ordersInProgress
    })
  );

  useDidUpdate(() => {
    setStatuses(
      combineOrdersAndResults({
        testResults,
        ordersInProgress
      })
    );
  }, [testResults, ordersInProgress]);

  return statuses;
}

export default function TestStatuses({
  ordersInProgress = [],
  testResults = [],
  handleSelect,
  heading,
  defaultOpen,
  selectProps
}) {
  const statuses = useTestStatuses({
    testResults,
    ordersInProgress
  });

  return (
    <SelectExpand
      {...selectProps}
      push={false}
      defaultOpen={defaultOpen}
      SelectDropdownIcon={SelectDropdownStatusIcon}
      top={
        <Box display="flex" alignItems="center">
          <TestIcon fill="white" width={20} />
          <Text color="white" fontFamily="gilroyBold" ml="10px">
            {heading}
          </Text>
        </Box>
      }
      callback={testStatus => {
        handleSelect(testStatus);
      }}
      items={statuses.map((testStatus, index) => {
        const resultsAvailable =
          testStatus.status === RESULTS_AVAILABLE || testStatus.status === CONSULT_NEEDED;
        return {
          value: testStatus,
          content: (
            <Box display="flex" justifyContent="space-between" textAlign="left">
              <Box flex={1} pr={2} width={"50%"}>
                <Box>
                  <SpanText lineHeight={1} color="white">
                    {testStatus.name}
                  </SpanText>
                </Box>
                <Box>
                  <SpanText lineHeight={1} color="#818181">
                    {testStatus.created}
                  </SpanText>
                </Box>
              </Box>
              {/* flexShrink on this right side box will cause the word contained to not wrap */}
              <Box pl={2} textAlign="right" width={"50%"}>
                <SpanText
                  lineHeight={1}
                  color={resultsAvailable ? "green" : "white"}
                  underline={resultsAvailable}
                >
                  {testStatus.status}
                </SpanText>
              </Box>
            </Box>
          )
        };
      })}
    />
  );
}

TestStatuses.defaultProps = {
  heading: "Your tests",
  ordersInProgress: [],
  testResults: [],
  defaultOpen: true,
  selectProps: {
    maxWidth: 500,
    maxHeight: 500,
    px: 20,
    py: 25
  }
};

TestStatuses.propTypes = {
  testResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelect: PropTypes.func,
  defaultOpen: PropTypes.bool,
  heading: PropTypes.string
};

// postpone props till later because ths isn't used atm
// or delete if we don't need it
export function TestStatusesLoader({ handleSelect, selectProps = {}, isPartnerView, clientId }) {
  return (
    <Box dataComponentName="Test statuses loader">
      <DataLoader
        query={isPartnerView ? CLIENT_TESTS : USER_TESTS_AND_ORDERS_QUERY}
        variables={isPartnerView ? { userId: clientId } : { allTests: true, excludeRetests: true }}
        loading={<TestStatuses heading="Loading" selectProps={selectProps} />}
        // Don't rely on the cache as the Stripe webhook is likely to mutate orders post checkout, rendering cached order statuses stale
        fetchPolicy="network-only"
        render={({ ordersInProgress = [], userTests }) => {
          if (ordersInProgress.length > 0 || userTests.length > 0) {
            return (
              <TestStatuses
                heading={isPartnerView ? "Available tests" : "Your tests"}
                ordersInProgress={ordersInProgress}
                testResults={userTests}
                handleSelect={handleSelect}
                selectProps={selectProps}
              />
            );
          } else {
            return <TestStatuses heading="No orders" selectProps={selectProps} />;
          }
        }}
      ></DataLoader>
    </Box>
  );
}
