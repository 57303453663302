import { useCallback, useEffect, useState } from "react";

import { FormProvider } from "react-hook-form";

import Box from "components/Box";
import Errors from "components/Errors";
import { FadeTransition } from "components/animations/FadeTransition";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { theme } from "core/theme";
import { CREATE_PATIENT_MUTATION } from "graphql/organisations/mutations";
import { ORGANISATION_LIST_QUERY } from "graphql/organisations/queries/organisation";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import ControlledRadioGroup from "tpo/ControlledRadioGroup";
import DateInput from "tpo/DateInput";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function CreatePatientForm({
  handleSuccess,
  handleFailure,
  mutationOptions,
  ...props
}) {
  const { organisation } = useOrganisationContext();
  const [underEighteen, setUnderEighteen] = useState(false);

  mutationOptions = {
    refetchQueries: [{ query: ORGANISATION_LIST_QUERY }],
    ...mutationOptions
  };

  const { methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: CREATE_PATIENT_MUTATION,
    mutationName: "createPatientMutation",
    mutationOptions,
    handleSuccess,
    handleFailure,
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      sex: "",
      dateOfBirth: "",
      underEighteenRangeAcknowledgement: false,
      guardianFirstName: "",
      guardianLastName: "",
      guardianEmail: "",
      sendInvite: false
    },
    ...props
  });

  const handleCreatePatientSubmit = useCallback(
    input => {
      let variables = {
        ...input,
        organisation: parseInt(organisation.id),
        underEighteenRangeAcknowledgement: undefined
      };

      if (!underEighteen) {
        variables = {
          ...variables,
          guardianFirstName: undefined,
          guardianLastName: undefined,
          guardianEmail: undefined
        };
      }

      return onSubmit(variables);
    },
    [organisation, onSubmit, underEighteen]
  );

  const watchDateOfBirth = methods.watch("dateOfBirth");
  const acknowledgedAgeRanges = methods.watch("underEighteenRangeAcknowledgement");

  useEffect(() => {
    if (watchDateOfBirth) {
      const dob = new Date(watchDateOfBirth);
      const ageDiff = Date.now() - dob.getTime();
      const ageDate = new Date(ageDiff);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      if (age < 18) {
        setUnderEighteen(true);
      } else {
        setUnderEighteen(false);
      }
    }
  }, [watchDateOfBirth]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleCreatePatientSubmit)}>
        <Box pb={theme.spacing.section.pb}>
          <Box fontFamily="gilroyBold" fontSize={28}>
            Enter Patient Details
          </Box>
          <Spacer py={15} />
          <Stack gap={20}>
            <ControlledFormField
              name="firstName"
              label="First name"
              Component={FloatingLabelInput}
            />
            <ControlledFormField name="lastName" label="Last name" Component={FloatingLabelInput} />
            <ControlledFormField name="email" label="Email" Component={FloatingLabelInput} />
          </Stack>
          <Spacer py={30} />
          <FormControl
            label={
              <Box as="h2" fontFamily="gilroyBold" fontSize={18} lineHeight="36px">
                Sex
              </Box>
            }
          >
            <Stack gap={20}>
              <ControlledRadioGroup
                Container={Stack}
                containerProps={{
                  gap: 20
                }}
                name="sex"
                values={[
                  {
                    label: "Male",
                    value: "Male"
                  },
                  {
                    label: "Female",
                    value: "Female"
                  }
                ]}
              />
            </Stack>
          </FormControl>
          <Spacer py={2} />
          <FormControl
            label={
              <Box as="h2" fontFamily="gilroyBold" fontSize={18} lineHeight="36px">
                Date of birth
              </Box>
            }
          >
            <ControlledFormField
              name="dateOfBirth"
              Component={DateInput}
              getDay={dateStr => (dateStr ? dateStr.split("-")[2] : "")}
              getMonth={dateStr => (dateStr ? dateStr.split("-")[1] : "")}
              getYear={dateStr => (dateStr ? dateStr.split("-")[0] : "")}
              constructDate={({ day, month, year }) => {
                return `${year}-${month}-${day}`;
              }}
            />
          </FormControl>
          <Spacer py={15} />
          <FadeTransition in={underEighteen}>
            <Box fontFamily="gilroyBold" fontSize={18} lineHeight="36px" color="red">
              User is under 18 years old
            </Box>
            <Spacer py={15} />
            <Box fontFamily="gilroyMedium" fontSize={16}>
              Because this user is a minor please enter the details of the legal guardian that will
              be responsible for them.
            </Box>
            <Spacer py={15} />
            <Stack gap={20}>
              <ControlledFormField
                name="guardianFirstName"
                label="Guardian first name"
                Component={FloatingLabelInput}
              />
              <ControlledFormField
                name="guardianLastName"
                label="Guardian last name"
                Component={FloatingLabelInput}
              />
              <ControlledFormField
                name="guardianEmail"
                label="Guardian email"
                Component={FloatingLabelInput}
              />
            </Stack>
            <Spacer py={15} />

            <ControlledFormField
              name="underEighteenRangeAcknowledgement"
              Component={Checkbox}
              label="I understand that not all tests will come with appropriate ranges for users under the age of 18"
            />
            <Spacer py={15} />
          </FadeTransition>
          <ControlledFormField
            name="sendInvite"
            Component={Checkbox}
            label="Send patient invite to sign up to the Omnos platform"
          />
          <Spacer py={30} />
          <Center>
            <ButtonV2
              color="green"
              rightIcon={<ChevronComponent />}
              disabled={acknowledgedAgeRanges === false && underEighteen}
            >
              submit
            </ButtonV2>
          </Center>
          {nonFieldError && <Errors>{nonFieldError}</Errors>}
        </Box>
      </form>
    </FormProvider>
  );
}
