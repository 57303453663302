import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";
import { buildUserTestBrushChartConfiguration } from "utils/cot/lineGraphs";

const CONSIDERED_TESTS_QUERY = gql`
  query ConsideredTestsQuery {
    userSubmissions {
      id
      complete
      status
      hasExpiredAnswers
      questionnaireDate
      firstAnswerExpiry
      lastAnswerExpiry
    }
    userTests {
      id
      testProduct {
        id
        productCode
        productFamily {
          id
          name
          familyColour
        }
      }
      freshDate
      firstDatapointExpiry
      lastDatapointExpiry
    }
  }
`;

export const TestBrushConfigContext = createContext();

export function TestBrushConfigContextProvider({ children }) {
  const [loadConsideredTests, { data }] = useLazyQuery(CONSIDERED_TESTS_QUERY, {
    fetchPolicy: "cache-first"
  });

  const [relevantConfigurations, setRelevantConfigurations] = useState({});

  const getRelevantConfig = useCallback(
    (resultId, productCodes) => {
      if (!resultId || !data) return {};

      if (relevantConfigurations[resultId]) {
        return relevantConfigurations[resultId];
      }

      const { userTests, userSubmissions } = data;
      let relevantUserTests;

      if (productCodes && productCodes.length) {
        relevantUserTests = data.userTests.filter(
          userTest =>
            userTest?.testProduct && productCodes.includes(userTest.testProduct.productCode)
        );
      } else {
        relevantUserTests = userTests;
      }

      if (relevantUserTests && userSubmissions) {
        let [testBrushConfig, testAnnotations] = buildUserTestBrushChartConfiguration({
          consideredUserTests: relevantUserTests,
          consideredSubmissions: userSubmissions,
          parentChartId: resultId
        });

        setRelevantConfigurations(prev => ({
          ...prev,
          [resultId]: { testBrushConfig, testAnnotations }
        }));

        return { testBrushConfig, testAnnotations };
      }
    },
    [data, relevantConfigurations]
  );

  const api = useMemo(() => ({ getRelevantConfig, loadConsideredTests }), [
    getRelevantConfig,
    loadConsideredTests
  ]);

  return <TestBrushConfigContext.Provider value={api}>{children}</TestBrushConfigContext.Provider>;
}

export function useTestBrushConfigContext() {
  const context = useContext(TestBrushConfigContext);

  useEffect(() => {
    context.loadConsideredTests();
  }, [context]);

  return context;
}
