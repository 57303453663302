import { forwardRef, useCallback, useContext, useMemo, useState } from "react";

import { Redirect, useParams, useHistory } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import CustomerPage from "components/CustomerPage";
import CustomerTabs from "components/CustomerTabs";
import SupplementModalContent from "components/SupplementModelContent";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import { theme } from "core/theme";
import useWindowSize from "hooks/use-window-size";
import useListControls from "hooks/useListControls";
import { capitalize } from "lodash";
import { buildSegmentedProgressChartConfig } from "utils/charts/segmentedCharts";
import ButtonV2 from "v2/Buttons";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import { InternalTextLink } from "../components/Links";
import Loading from "../components/Loading";
import { ExecutionProvider } from "../contexts/ExecutionContext";
import {
  ERROR_404_URL,
  RECOMMENDATIONS_URL,
  getPatientFoodListUrl,
  getPatientRecommendationsUrl,
  getPatientSupplementUrlUsingSlug,
  getPartnerTPOSubsectorUrl,
  getTPOFoodUrl,
  getTPOSubsectorUrl,
  getTPOSupplementUrl,
  getThemeUrl,
  getPartnerShopTabUrl,
  getShopSupplementUrl
} from "../core/urls";
import { CORE_USER_SUBSECTOR_FIELDS, USER_SUBSECTOR_FIELDS } from "../graphql/tpo/common/types";
import {
  USER_DATAPOINT_FIELDS_FOR_COT,
  USER_RESULT_FIELDS_FOR_COT
} from "../graphql/tpo/results/types";
import useDocTitle from "../hooks/use-doc-title";
import { useDataLoader } from "../hooks/useDataLoader";
import { PanelBoxV2 } from "./Boxes";
import ChartModal from "./ChartModal";
import { SegmentedProgressChart } from "./Charts";
import ChevronComponent from "./Chevron";
import { CollapseableText } from "./CollapseableText";
import { NoItems } from "./EmptySearch";
import { ExpiredMarkersToggleSwitch } from "./ExpiredMarkersToggleSwitch";
import Group from "./Group";
import InfiniteScroll, { InfiniteScrollContext } from "./InfiniteScroll";
import Jumbotron from "./Jumbotron";
import { LazyTabs, TabsContext } from "./LazyTabs";
import Menu, { Button } from "./Menu";
import {
  LoadUserSubsector,
  RecommendationModalContent,
  TABS,
  recommendationsMapper,
  RecommendationTabGrid
} from "./Recommendations";
import SegmentedControl from "./SegmentedControl";
import SortMenu from "./SortMenu";
import Spacer from "./Spacer";
import Stack from "./Stack";
import { Search } from "./TextInput";
import {
  BiomarkersTested,
  DatapointChartCard,
  USER_DATAPOINT_QUERY,
  UserDatapointModelContent
} from "./UserDatapoint";
import {
  RelatedResultContext,
  ResultChartWithBiomarkersTested,
  USER_RESULT_QUERY,
  UserResultModelContent
} from "./UserResult";
import { CardListContext, Explanation } from "./UserResults";
import { ViewerContext } from "./Viewer";

export const USER_SUBSECTOR_QUERY = gql`
  query UserSubsectorQuery($themeSlug: String!, $subsectorSlug: String!, $userId: ID) {
    userTheme(themeSlug: $themeSlug, userId: $userId) {
      id
      name
      slug
    }
    userSubsector(subsectorSlug: $subsectorSlug, userId: $userId) {
      ...CoreUserSubsectorFields
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
      availableDatapointRatio
    }
  }
  ${CORE_USER_SUBSECTOR_FIELDS}
`;

const USER_SUBSECTOR_BIOMARKERS_CONNECTION_QUERY = gql`
  query TEST_BIOMARKERS_CONNECTIONS_QUERY(
    $userSubsectorId: ID!
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $excludeGenesRelated: Boolean
    $userId: ID
  ) {
    userDatapointConnections(
      userSubsectorId: $userSubsectorId
      after: $after
      first: $first
      orderBy: $orderBy
      search: $search
      excludeGenesRelated: $excludeGenesRelated
      userId: $userId
    ) {
      edges {
        cursor
        node {
          ...UserDatapointFieldsForCot
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${USER_DATAPOINT_FIELDS_FOR_COT}
`;

const USER_SUBSECTOR_RESULTS_QUERY = gql`
  query UserSubsectorResultsQuery(
    $subsectorSlug: String!
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $userId: ID
  ) {
    userSubsector(subsectorSlug: $subsectorSlug, userId: $userId) {
      id
      userResultConnections(after: $after, first: $first, orderBy: $orderBy, search: $search) {
        edges {
          cursor
          node {
            ...UserResultFieldsForCot
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${USER_RESULT_FIELDS_FOR_COT}
`;

const PAGE_SIZE = 10;

export const USER_SUBSECTORS_QUERY = gql`
  query UserSubsectorsQuery($themeSlug: String!, $userId: ID, $fresh: Boolean) {
    userSubsectors(themeSlug: $themeSlug, userId: $userId, fresh: $fresh) {
      ...UserSubsectorFields
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
    }
  }
  ${USER_SUBSECTOR_FIELDS}
`;

export function UserSubsectorMenuItem({ userSubsector, color }) {
  const { interpretationColor } = buildSegmentedProgressChartConfig({
    colours: userSubsector.chartConfiguration.colours,
    value: userSubsector.chartConfiguration.normalisedRange.value
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" gap={10} color={color}>
      <Box fontFamily="gilroyBold" fontSize={14} lineHeight={1.7} color="inherit">
        {userSubsector.name}
      </Box>
      <Box bg={interpretationColor} height={10} width={10} borderRadius="100%" />
    </Box>
  );
}

export function useUserSubsectorsMenu({ themeSlug }) {
  const [open, setOpen] = useState(false);
  const viewerCtx = useContext(ViewerContext);

  const { data } = useDataLoader({
    query: USER_SUBSECTORS_QUERY,
    variables: {
      themeSlug,
      userId: viewerCtx?.userId
    }
  });

  const userSubsectors = data?.userSubsectors || [];

  return {
    userSubsectors,
    open,
    setOpen
  };
}

function UserSubsectorsMenu({ userSubsector: pageUserSubsector }) {
  const { themeSlug } = useParams();
  const { userSubsectors, open, setOpen } = useUserSubsectorsMenu({
    themeSlug
  });
  const viewerCtx = useContext(ViewerContext);
  const history = useHistory();

  return (
    <Box>
      <Menu opened={open} onChange={setOpen} trigger="hover" xOffset={40}>
        <Menu.Target>
          <Button opened={open} lineHeight="24px" paddingY="16px" paddingX="40px" maxWidth="100%">
            <span
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "100%"
              }}
            >
              {pageUserSubsector.name}
            </span>
          </Button>
        </Menu.Target>
        {!!userSubsectors.length && (
          <Menu.Dropdown
            mt={2}
            py={2}
            zIndex={2}
            minWidth={310}
            maxWidth={310}
            height={500}
            maxHeight={500}
            overflowY="auto"
          >
            {userSubsectors.map(userSubsector => (
              <Menu.Item
                onClick={() =>
                  history.push(
                    viewerCtx?.userId
                      ? getPartnerTPOSubsectorUrl(themeSlug, userSubsector.slug, viewerCtx.userId)
                      : getTPOSubsectorUrl(themeSlug, userSubsector.slug)
                  )
                }
                key={userSubsector.id}
                selected={userSubsector.id === pageUserSubsector.id}
                py={16}
              >
                <UserSubsectorMenuItem userSubsector={userSubsector} />
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        )}
      </Menu>
    </Box>
  );
}

const listControls = [
  {
    label: "Results",
    value: "results"
  },
  {
    label: "Biomarkers",
    value: "datapoints"
  },
  {
    label: "Recommendations",
    value: "recommendations"
  }
];

export const ResultItemCard = forwardRef(({ item }, ref) => {
  const ctx = useContext(CardListContext);
  return (
    <ResultChartWithBiomarkersTested
      ref={ref}
      userResult={item}
      key={item.id}
      onClick={() =>
        ctx?.viewCard({
          type: "results",
          item
        })
      }
    />
  );
});

export function ListTemplate({
  ItemCard,
  controls,
  items,
  hasMore,
  loading,
  endCursor,
  fetchMore
}) {
  return (
    <>
      <Box py={[2, 20, 20]} />
      <PanelBoxV2 maxWidth={[1020]}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            gap={20}
            justifyContent={["flex-end"]}
            alignItems="center"
            flexWrap="wrap"
          >
            <Search
              value={controls.search}
              onChange={controls.setSearch}
              maxWidth={[null, 350]}
              minWidth={310}
              width="100%"
            />
            <Box display="flex" justifyContent="flex-end">
              <SortMenu
                value={controls.sort}
                setValue={controls.setSort}
                open={controls.sortMenuOpen}
                setOpen={controls.setSortMenuOpen}
                options={controls.sortOptions}
                placeholder="Sort By"
              />
            </Box>
          </Box>
          <Spacer py={[2]} />
          <Explanation display={["none", "block"]} />
          <Spacer py={[0, 20]} />
          <Box display="flex" flexDirection="column" gap={10}>
            {items?.length ? (
              <InfiniteScroll
                loader={<Loading />}
                hasMore={hasMore}
                loading={loading}
                next={() => {
                  if (endCursor) {
                    fetchMore({
                      variables: {
                        after: endCursor,
                        first: 10
                      }
                    });
                  }
                }}
                items={items}
              >
                <InfiniteScrollContext.Consumer>
                  {({ itemsList, setBottomElement }) => (
                    <>
                      {itemsList.slice(0, -1).map(item => (
                        <ItemCard key={item.id} item={item} />
                      ))}
                      {itemsList.slice(-1).map(item => (
                        <ItemCard key={item.id} item={item} ref={setBottomElement} />
                      ))}
                    </>
                  )}
                </InfiniteScrollContext.Consumer>
              </InfiniteScroll>
            ) : (
              <NoItems search={controls.search} loading={loading} />
            )}
          </Box>
        </Box>
      </PanelBoxV2>
    </>
  );
}

function Results() {
  const { subsectorSlug } = useParams();
  const viewerContext = useContext(ViewerContext);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Results: A - Z",
        value: "result__name"
      },
      {
        label: "Results: Z - A",
        value: "-result__name"
      },
      {
        label: "Results: Low to High",
        value: "-normalised_order"
      },
      {
        label: "Results: High to Low",
        value: "normalised_order"
      }
    ]
  });

  const { data, loading, fetchMore } = useDataLoader({
    query: USER_SUBSECTOR_RESULTS_QUERY,
    variables: {
      subsectorSlug,
      userId: viewerContext?.userId,
      first: PAGE_SIZE,
      after: "",
      orderBy: controls.sort,
      search: controls.debouncedSearch
    }
  });

  const userResults =
    data?.userSubsector?.userResultConnections?.edges?.map(edge => edge.node) || [];
  const hasMore = data?.userSubsector?.userResultConnections?.pageInfo?.hasNextPage;
  const endCursor = data?.userSubsector?.userResultConnections?.pageInfo?.endCursor;

  return (
    <ListTemplate
      items={userResults}
      loading={loading}
      fetchMore={fetchMore}
      hasMore={hasMore}
      endCursor={endCursor}
      ItemCard={ResultItemCard}
      controls={controls}
    />
  );
}

const DatapointItemCard = forwardRef(({ item }, ref) => {
  const ctx = useContext(CardListContext);
  return (
    <DatapointChartCard
      userDatapoint={item}
      key={item.id}
      onClick={() => {
        ctx?.viewCard({
          type: "datapoints",
          item
        });
      }}
      ref={ref}
    />
  );
});

function Datapoints({ userSubsector }) {
  const viewerContext = useContext(ViewerContext);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Biomarkers: A - Z",
        value: "datapoint__category,datapoint__name"
      },
      {
        label: "Biomarkers: Z - A",
        value: "-datapoint__category,-datapoint__name"
      },
      {
        label: "Biomarkers: Low to High",
        value: "-normalised_order"
      },
      {
        label: "Biomarkers: High to Low",
        value: "normalised_order"
      }
    ]
  });

  const { data, loading, fetchMore } = useDataLoader({
    query: USER_SUBSECTOR_BIOMARKERS_CONNECTION_QUERY,
    variables: {
      userSubsectorId: userSubsector.id,
      userId: viewerContext?.userId,
      first: PAGE_SIZE,
      after: "",
      orderBy: controls.sort,
      search: controls.debouncedSearch,
      excludeGenesRelated: true
    }
  });

  const userDatapoints = data?.userDatapointConnections?.edges.map(edge => edge.node) || [];
  const hasMore = data?.userDatapointConnections?.pageInfo?.hasNextPage;
  const endCursor = data?.userDatapointConnections?.pageInfo?.endCursor;

  return (
    <ListTemplate
      items={userDatapoints}
      loading={loading}
      fetchMore={fetchMore}
      hasMore={hasMore}
      endCursor={endCursor}
      ItemCard={DatapointItemCard}
      controls={controls}
    />
  );
}

function RecommendationTabsProvider({ children, examples, userSubsector }) {
  const { viewCard } = useContext(CardListContext);
  const history = useHistory();
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  const tabs = useMemo(
    () => [
      ...(userSubsector.thingsToDoCount > 0
        ? [
            {
              ...TABS.thingsToDo,
              getItems: data => {
                return recommendationsMapper(data).map(item => ({
                  ...item,
                  onClick: () => {
                    viewCard({
                      type: "recommendations",
                      item,
                      bg: item.bg
                    });
                  }
                }));
              }
            }
          ]
        : []),
      ...(userSubsector.thingsToAvoidCount > 0
        ? [
            {
              ...TABS.thingsToAvoid,
              getItems: data => {
                return recommendationsMapper(data).map(item => ({
                  ...item,
                  onClick: () => {
                    viewCard({
                      type: "recommendations",
                      item,
                      bg: item.bg
                    });
                  }
                }));
              }
            }
          ]
        : []),
      ...(userSubsector.thingsToTakeCount > 0
        ? [
            {
              ...TABS.thingsToTake,
              getItems: data =>
                TABS.thingsToTake.getItems(data).map(item => ({
                  ...item,
                  onClick: () => {
                    viewCard({
                      type: "supplements",
                      item,
                      bg: item.bg
                    });
                  }
                }))
            }
          ]
        : []),
      ...(userSubsector.peopleToSeeCount > 0
        ? [
            {
              ...TABS.peopleToSee,
              getItems: data => {
                return recommendationsMapper(data).map(item => ({
                  ...item,
                  onClick: () => {
                    viewCard({
                      type: "recommendations",
                      item
                    });
                  }
                }));
              }
            }
          ]
        : []),
      ...(examples.length
        ? [
            {
              ...TABS.foodList,
              getItems: data =>
                TABS.foodList.getItems(data).map(item => ({
                  ...item,
                  onClick: () => {
                    if (clientId) {
                      history.push(getPatientFoodListUrl(clientId));
                    } else {
                      history.push(getTPOFoodUrl());
                    }
                  }
                }))
            }
          ]
        : [])
    ],
    [examples, userSubsector, viewCard, history, clientId]
  );

  return <LazyTabs tabs={tabs}>{children}</LazyTabs>;
}

function Recommendations({ userSubsector }) {
  const history = useHistory();
  const viewerContext = useContext(ViewerContext);
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  return (
    <LoadUserSubsector
      userSubsector={userSubsector}
      render={props =>
        props.userSubsector ? (
          <ExecutionProvider
            context={{
              userSubsector: props.userSubsector,
              userId: viewerContext?.userId,
              fresh: !includeExpiredMarkers
            }}
          >
            <Box py={[2, 2, 20]} />
            <RecommendationTabsProvider
              examples={props.examples}
              userSubsector={props.userSubsector}
            >
              <TabsContext.Consumer>
                {({ tabs, setTab, loading, data, tab, isSelected, tabId }) => (
                  <Stack gap={[30, 30, 40]}>
                    <RecommendationTabGrid
                      tabs={tabs}
                      isSelected={isSelected}
                      tabId={tabId}
                      setTab={setTab}
                      loading={loading}
                      data={data}
                      tab={tab}
                    />
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <ButtonV2
                        color="dark"
                        onClick={() => {
                          return viewerContext?.userId
                            ? history.push(getPatientRecommendationsUrl(viewerContext.userId))
                            : history.push(RECOMMENDATIONS_URL);
                        }}
                        rightIcon={<ChevronComponent />}
                        size={["sm", "sm", "md"]}
                      >
                        prioritised recommendations
                      </ButtonV2>
                    </Box>
                  </Stack>
                )}
              </TabsContext.Consumer>
            </RecommendationTabsProvider>
          </ExecutionProvider>
        ) : null
      }
    />
  );
}

export function useCardList() {
  const [cardOpen, setCardOpen] = useState();

  const viewCard = useCallback(value => setCardOpen(value), [setCardOpen]);
  const closeCard = useCallback(() => setCardOpen(false), []);

  return {
    cardOpen,
    setCardOpen,
    viewCard,
    closeCard
  };
}

function SubsectorSegmentedProgressChart({ userSubsector }) {
  const { includeExpiredMarkers } = useChangeOverTimeContext();
  const chartProps = buildSegmentedProgressChartConfig({
    colours: userSubsector.chartConfiguration.colours,
    value: includeExpiredMarkers ? userSubsector.invertedScore : userSubsector.invertedFreshScore,
    fill: true,
    backgroundColor: "inherit"
  });
  return <SegmentedProgressChart {...chartProps} />;
}

export function ModelContent({ type, item }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;
  const history = useHistory();

  if (type === "results") {
    return (
      <DataLoader
        query={USER_RESULT_QUERY}
        variables={{
          userId: clientId,
          userResultId: item.id
        }}
        render={({ userResult, relatedGenes }) => (
          <UserResultModelContent userResult={userResult} relatedGenes={relatedGenes} />
        )}
      />
    );
  } else if (type === "datapoints") {
    return (
      <DataLoader
        query={USER_DATAPOINT_QUERY}
        variables={{
          userDatapointId: item.id,
          userId: clientId
        }}
        render={({ userDatapoint }) => <UserDatapointModelContent userDatapoint={userDatapoint} />}
      />
    );
  } else if (type === "recommendations") {
    return <RecommendationModalContent recommendationId={item.id} />;
  } else if (type === "supplements") {
    return (
      <SupplementModalContent
        slug={item.slug}
        onViewInShop={() => {
          if (clientId) {
            history.push(`${getPartnerShopTabUrl("supplements")}?slug=${item.slug}`);
          } else {
            history.push(getShopSupplementUrl(item.slug));
          }
        }}
      />
    );
  }

  return null;
}

export function UserSubsectorBody({ aboveMainChart, userSubsector }) {
  const [list, setList] = useState("results");
  const [listMenuOpen, setListMenuOpen] = useState(false);

  const { viewCard, closeCard, cardOpen, setCardOpen } = useCardList();

  const cardListApi = useMemo(
    () => ({
      viewCard,
      closeCard
    }),
    [viewCard, closeCard]
  );

  const modalBg = cardOpen?.bg || "white";

  const { width } = useWindowSize();
  const isMobile = width < 640;

  return (
    <>
      <PanelBoxV2
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          bg: "white"
        }}
        inner={{
          px: [20, 20, "5.5vw"]
        }}
      >
        {aboveMainChart}
        <PanelBoxV2
          maxWidth={1020}
          inner={{
            pt: [20, 20, 40],
            pb: [20, 20, 40],
            bg: "brand",
            borderRadius: 5,
            px: [15, 15, 0]
          }}
        >
          <PanelBoxV2 maxWidth={760}>
            <Box color="white" fontSize={[16, 16, 18]} fontFamily="gilroyBold">
              {userSubsector.name}
            </Box>
            <Spacer py={2} />
            <SubsectorSegmentedProgressChart userSubsector={userSubsector} />
            <Spacer py={[20]} />
            <BiomarkersTested
              chartProps={{
                backgroundColor: "rgba(23, 34, 77, 0.30)",
                height: 5,
                showText: false,
                showChevron: false,
                enclosed: false,
                foregroundColor: "green"
              }}
              color="white"
              value={Math.round(userSubsector?.availableDatapointRatio * 100)}
            />
          </PanelBoxV2>
        </PanelBoxV2>
        <Spacer py={[2, 2, 20]} />
        <PanelBoxV2
          maxWidth={760}
          inner={{
            px: [20, 20, 0]
          }}
        >
          <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
            {userSubsector.name}
          </Box>
          {userSubsector.description && (
            <>
              <Spacer py={[2, 2, 20]} />
              <CollapseableText fontSize={[14, 14, 16]}>
                {userSubsector.description}
              </CollapseableText>
            </>
          )}
        </PanelBoxV2>
      </PanelBoxV2>
      <CardListContext.Provider value={cardListApi}>
        <PanelBoxV2
          outer={{
            pt: theme.spacing.section.pt,
            bg: "haze"
          }}
          inner={{
            px: [20, 20, "5.5vw"]
          }}
        >
          <Box display="flex" flexDirection={["row"]} justifyContent={["center"]}>
            <Box display={["none", "block"]}>
              <SegmentedControl data={listControls} onChange={setList} value={list} height={48} />
            </Box>
          </Box>
          <Box display={["block", "none"]}>
            <Menu opened={isMobile && listMenuOpen} onChange={setListMenuOpen} trigger="hover">
              <Menu.Target>
                <Button
                  open={listMenuOpen}
                  fullWidth
                  lineHeight="24px"
                  paddingY="16px"
                  paddingX="40px"
                >
                  {listControls.find(item => item.value === list).label}
                </Button>
              </Menu.Target>
              <Menu.Dropdown
                mt={2}
                py={2}
                zIndex={2}
                minWidth={200}
                width="calc(100% - 40px)"
                mx="auto"
              >
                {listControls.map(item => (
                  <Menu.Item
                    key={item.value}
                    onClick={() => setList(item.value)}
                    selected={item.value === list}
                    fontSize={14}
                    lineHeight={1.7}
                    py={16}
                  >
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </Box>
          {list === "results" && <Results />}
          {list === "datapoints" && (
            <RelatedResultContext.Provider
              value={{
                openModal: result => {
                  viewCard({
                    type: "results",
                    item: result
                  });
                }
              }}
            >
              <Datapoints userSubsector={userSubsector} />
            </RelatedResultContext.Provider>
          )}
          <ChartModal bg={modalBg} close={() => setCardOpen(undefined)} show={!!cardOpen?.type}>
            <ModelContent type={cardOpen?.type} item={cardOpen?.item} />
          </ChartModal>
        </PanelBoxV2>
        {/* recommendations are outside of panel box because the lozenges need to be full width */}
        {list === "recommendations" && <Recommendations userSubsector={userSubsector} />}
        <Spacer pt={theme.spacing.section.pb} />
      </CardListContext.Provider>
    </>
  );
}

function Delimiter({ color, fontFamily, fontSize, symbol = "/" }) {
  return (
    <Box color={color} fontFamily={fontFamily} fontSize={fontSize}>
      {symbol}
    </Box>
  );
}

function UserSubsector({ userTheme, userSubsector }) {
  useDocTitle(userSubsector?.name);

  if (!(userTheme && userSubsector)) {
    return <Redirect to={ERROR_404_URL} />;
  }

  return <UserSubsectorBody userTheme={userTheme} userSubsector={userSubsector} />;
}

function UserSubsectorPage() {
  const { themeSlug, subsectorSlug, clientId } = useParams();

  const { data } = useQuery(USER_SUBSECTOR_QUERY, {
    variables: { themeSlug, subsectorSlug, userId: clientId }
  });

  const userTheme = data?.userTheme;
  const userSubsector = data?.userSubsector;

  return (
    <CustomerPage
      bg="haze"
      jumbotronProps={{
        children: (
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            alignItems={[null, null, "center"]}
            gap={[10]}
          >
            <Group gap={10}>
              <InternalTextLink
                underline
                href={getThemeUrl(themeSlug)}
                color="white"
                fontFamily="gilroyBold"
                fontSize={[28, 28, 36]}
              >
                {capitalize(userTheme?.name)}
              </InternalTextLink>
              <Delimiter color="white" fontFamily="gilroyBold" fontSize={[28, 28, 36]} />
            </Group>
            {!!userSubsector && (
              <UserSubsectorsMenu userSubsector={userSubsector} key="lastBreadcrumb" />
            )}
          </Box>
        ),
        top: <ExpiredMarkersToggleSwitch />
      }}
    >
      <CustomerTabs selectedTab="/theme/health" />
      {!!data && (
        <ViewerContext.Provider
          value={{
            userId: clientId
          }}
        >
          <UserSubsector userTheme={userTheme} userSubsector={userSubsector} />
        </ViewerContext.Provider>
      )}
    </CustomerPage>
  );
}

export default UserSubsectorPage;
